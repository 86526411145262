<script>
    import VaccinationText from "./VaccinationText.svelte";
    import Disclaimer from "../Disclaimer.svelte";

    import { Link } from "svelte-routing";
    import { _ } from "svelte-i18n";
</script>

<div class="max-w-4xl mx-auto relative">
  <VaccinationText />
  <Disclaimer file="disclaimer-impfzahlen" />
</div>