<script>
  import { fade } from 'svelte/transition';
</script>

<div
  in:fade
  style="z-index: 401"
  class="absolute -top-12 md:-top-6 bg-white bg-opacity-50 z-10 pointer-events-none max-w-lg"
>
  <slot />
</div>
