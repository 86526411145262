<script>
  import naturalSort from '../utils/naturalSort.js';

  export let data;
  export let columns;
  export let defaultSortBy = null;
  export let limit = undefined;

  let sortBy = defaultSortBy;
  let sortDirection = 'DESC';

  const renderItem = (column, item) => {
    if (column.format && typeof column.format === 'function')
      return column.format(item[column.key]);
    return item[column.key];
  };

  $: setSortBy = (key) => {
    if (sortBy === key) {
      if (sortDirection === 'ASC') {
        sortDirection = 'DESC';
        sortBy = null;
        return;
      } else if (sortDirection === 'DESC') {
        sortDirection = 'ASC';
        return;
      }
    }
    sortDirection = 'DESC';
    sortBy = key;
  };

  $: sortedData = sortBy
    ? [...(data || [])].sort((a, b) => naturalSort(a, b, sortBy, sortDirection))
    : data;

  $: sortedLimitedData = sortedData.slice(0, limit);
</script>

<div>
  <table>
    <thead>
      <tr>
        {#each columns as column}
          <td class="cursor-pointer" on:click={() => setSortBy(column.key)}>
            <div
              class="header-cell"
              class:sortedUp={sortBy === column.key && sortDirection === 'ASC'}
              class:sortedDown={sortBy === column.key &&
                sortDirection === 'DESC'}
            >
              {column.label}
            </div>
          </td>
        {/each}
      </tr>
    </thead>
    {#each sortedLimitedData as item}
      <tr>
        {#each columns as column}
          <td>{renderItem(column, item)}</td>
        {/each}
      </tr>
    {/each}
  </table>
</div>

<style>
  div {
    overflow-x: scroll;
    width: 100%;
  }
  table {
    border-collapse: collapse;
    min-width: 100%;
  }

  thead {
    @apply bg-coldGray-200 font-bold;
  }

  tr {
    border-bottom: 1px solid #d9e3e8;
  }

  tr:nth-child(odd) {
    @apply bg-coldGray-100;
  }

  td {
    text-align: left;
    padding: 4px;
  }

  .header-cell {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .header-cell:after {
    content: ' ';
    margin-left: 0.5em;
  }

  .sortedDown:after {
    content: '▾';
  }

  .sortedUp:after {
    content: '▴';
  }
</style>
