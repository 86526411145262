<script>
  import { _ } from 'svelte-i18n';
  import dayjs from 'dayjs';
  import { timelineDosesStore } from '../../data-store';
  import Chart from './Chart.svelte';
  import Legend from '../Legend.svelte';

  $: dataset = (() => {
    if ($timelineDosesStore) {
      const data = $timelineDosesStore;
      const dosesAustria = data?.filter((entry) => entry['state_id'] === 10);

      const aggregateCumulativeDosesByDay = (prevDataPerDay, entry) => {
        const date = dayjs(entry['date']).format('YYYY-MM-DD');
        if (!prevDataPerDay[date])
          prevDataPerDay[date] = {
            date,
            firstDoseCumulative: 0,
            secondDoseCumulative: 0,
            thirdDoseCumulative: 0,
            boosterDoseCumulative: 0
          };

        if (entry.vaccination === 1)
          prevDataPerDay[date].firstDoseCumulative +=
            entry.vaccinations_administered_cumulative;
        if (entry.vaccination === 2)
          prevDataPerDay[date].secondDoseCumulative +=
            entry.vaccinations_administered_cumulative;
        if (entry.vaccination === 3)
          prevDataPerDay[date].thirdDoseCumulative +=
            entry.vaccinations_administered_cumulative;
        if (entry.vaccination === "4+")
          prevDataPerDay[date].boosterDoseCumulative +=
            entry.vaccinations_administered_cumulative;
        return prevDataPerDay;
      };

      const computeDosesPerDay = (entry, i, arr) => {
        const previousEntry = i > 0 ? arr[i - 1] : null;
        const firstDose =
          entry.firstDoseCumulative - 
          (previousEntry?.firstDoseCumulative || 0);
        const secondDose =
          entry.secondDoseCumulative -
          (previousEntry?.secondDoseCumulative || 0);
        const thirdDose =
          entry.thirdDoseCumulative - 
          (previousEntry?.thirdDoseCumulative || 0);
        const boosterDose =
          entry.boosterDoseCumulative - 
          (previousEntry?.boosterDoseCumulative || 0);
        return {
          ...entry,
          firstDose,
          secondDose,
          thirdDose,
          boosterDose,
          dosesDaily: firstDose + secondDose + thirdDose + boosterDose
        };
      };

      const computeCumulativeDoses = (entry, i, arr) => {
        const previousEntry = i > 0 ? arr[i - 1] : null;
        return {
          ...entry,
          dosesCumulative:
            entry.firstDoseCumulative +
            entry.secondDoseCumulative +
            entry.thirdDoseCumulative +
            entry.boosterDoseCumulative
        };
      };

      const computeRollingAverage = (entry, i, arr) => {
        const previousWeekEntry = i - 7 > 0 ? arr[i - 7] : null;
        return {
          ...entry,
          dosesAdministredRollingAverage: Math.round(
            (entry.dosesCumulative -
              (previousWeekEntry?.dosesCumulative || 0)) /
              7
          )
        };
      };

      const dataPerDay = dosesAustria.reduce(aggregateCumulativeDosesByDay, {});
      const entries = Object.values(dataPerDay);
      return entries
        .map(computeDosesPerDay)
        .map(computeCumulativeDoses)
        .map(computeRollingAverage)
        .slice(-90);
    }
  })();

  const keys = ['boosterDose', 'thirdDose', 'secondDose', 'firstDose'];
  const colors = ['#366339', '#4d9553', '#91c88d', '#dfedda'];
</script>

<div id="vaccinationsPerDay">
  <div
    class="flex justify-between flex-col md:flex-row md:items-center mt-8 mb-16 md:mb-8"
  >
    <h2 class="text-xl font-bold">
      {$_('dashboard.vaccinationsPerDay.title')}
    </h2>
    <div class="legend flex-wrap">
      <div class="flex items-center space-x-2 p-2">
        <div
          class="line border-b-2 border-dashed border-blue w-6 h-2 transform -translate-y-1"
        />
        <span>{$_('dashboard.sevenDaysAverage')}</span>
      </div>

      <div class="flex items-center space-x-2 p-2">
        <div class="indicator bg-green-lightest" />
        <span>{$_('dashboard.firstDose')}</span>
      </div>

      <div class="flex items-center space-x-2 p-2">
        <div class="indicator bg-green-light" />
        <span>{$_('dashboard.secondDose')}</span>
      </div>

      <div class="flex items-center space-x-2 p-2">
        <div class="indicator bg-green-dark" />
        <span>{$_('dashboard.thirdDose')}</span>
      </div>
      <div class="flex items-center space-x-2 p-2">
        <div class="indicator bg-green-darkest" />
        <span>{$_('dashboard.boosterDose')}</span>
      </div>
    </div>
  </div>
  <div class="chart">
    {#if dataset}
      <Chart data={dataset} {keys} {colors} />
    {/if}
  </div>
</div>

<style>
  .chart {
    height: 300px;
  }

  .legend {
    @apply flex mt-2 items-center text-sm;
  }

  .indicator {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  .path-line {
    fill: none;
    stroke: #083853;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-width: 2;
    stroke-dasharray: 5, 5;
  }
</style>
