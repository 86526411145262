<script>
  import { _ } from 'svelte-i18n';
  import dayjs from 'dayjs';
  import { timelineDosesStore } from '../../data-store';
  import Chart from './Chart.svelte';
  import Legend from '../Legend.svelte';

  $: dataset = (() => {
    if ($timelineDosesStore) {
      const data = $timelineDosesStore;
      const dosesAustria = data?.filter((entry) => entry['state_id'] === 10);

      const aggregateCumulativeDosesByDay = (prevDataPerDay, entry) => {
        const date = dayjs(entry['date']).format('YYYY-MM-DD');
        if (!prevDataPerDay[date])
          prevDataPerDay[date] = {
            date,
            firstDoseCumulative: 0,
            secondDoseCumulative: 0,
            thirdDoseCumulative: 0,
            boosterDoseCumulative: 0
          };

        if (entry.vaccination === 1)
          prevDataPerDay[date].firstDoseCumulative +=
            entry.vaccinations_administered_cumulative;
        if (entry.vaccination === 2)
          prevDataPerDay[date].secondDoseCumulative +=
            entry.vaccinations_administered_cumulative;
        if (entry.vaccination === 3)
          prevDataPerDay[date].thirdDoseCumulative +=
            entry.vaccinations_administered_cumulative;
        if (entry.vaccination === "4+")
          prevDataPerDay[date].boosterDoseCumulative +=
            entry.vaccinations_administered_cumulative;
        return prevDataPerDay;
      };

      const computeDosesPerDay = (entry, i, arr) => {
        const previousEntry = i > 0 ? arr[i - 1] : null;
        const firstDose =
          entry.firstDoseCumulative - (previousEntry?.firstDoseCumulative || 0);
        const secondDose =
          entry.secondDoseCumulative -
          (previousEntry?.secondDoseCumulative || 0);
        const thirdDose =
          entry.thirdDoseCumulative - (previousEntry?.thirdDoseCumulative || 0);
        const boosterDose =
          entry.boosterDoseCumulative - 
          (previousEntry?.boosterDoseCumulative || 0);
        return {
          ...entry,
          firstDose,
          secondDose,
          thirdDose,
          boosterDose,
          dosesDaily: firstDose + secondDose + thirdDose + boosterDose
        };
      };

      const computeCumulativeDoses = (entry, i, arr) => {
        const previousEntry = i > 0 ? arr[i - 1] : null;
        return {
          ...entry,
          dosesCumulative:
            entry.firstDoseCumulative +
            entry.secondDoseCumulative +
            entry.thirdDoseCumulative +
            entry.boosterDoseCumulative
        };
      };

      const dataPerDay = dosesAustria.reduce(aggregateCumulativeDosesByDay, {});
      const entries = Object.values(dataPerDay);
      return entries
        .map(computeDosesPerDay)
        .map(computeCumulativeDoses)
        .reverse()
        .filter((_, i) => i % 7 === 0)
        .reverse();
    }
  })();

  const keys = [
    'firstDoseCumulative',
    'secondDoseCumulative',
    'thirdDoseCumulative',
    'boosterDoseCumulative'
  ];
  $: labels = [
    $_('dashboard.firstDose'),
    $_('dashboard.secondDose'),
    $_('dashboard.thirdDose'),
    $_('dashboard.boosterDose')
  ];
  const colors = ['#dfedda', '#91c88d', '#4d9553', '#366339'];
</script>

<div id="vaccinationsCumulative">
  <div
    class="flex justify-between flex-col md:flex-row md:items-center mt-8 mb-16 md:mb-8"
  >
    <h2 class="text-xl font-bold">
      {$_('dashboard.vaccinationsCumulative.title')}
    </h2>
    <Legend {keys} {colors} {labels} />
  </div>
  <div class="chart">
    {#if dataset}
      <Chart data={dataset} {keys} {colors} />
    {/if}
  </div>
</div>

<style>
  .chart {
    height: 300px;
  }
</style>
