<script>
  import { _ } from 'svelte-i18n';
  import { vaccinationsDemographicsStore, populationStore  } from '../../data-store';
  import ChartByGenderAndAgeGroup from './ChartByGenderAndAgeGroup.svelte';
  import ChartByAgeGroup from './ChartByAgeGroup.svelte';

  $: datasetByAgeGroupAndGender = null;
  $: datasetByAgeGroup = null;
  $: console.log(datasetByAgeGroupAndGender);
  $: console.log(datasetByAgeGroup);

  $: if ($vaccinationsDemographicsStore && $populationStore) {
    const vaccinationDataAustria = $vaccinationsDemographicsStore?.filter(
      (entry) => entry['state_id'] === 10
    );

    const ageGroups = $populationStore.filter(d => d.gender == "Female").map(d => d.age_group);
    console.log(ageGroups);

    const datasetNonBinary = ageGroups.map(entry => {
      return {
        ageGroup: entry,
        gender: "NonBinary",
        population: 0,
        immunised: vaccinationDataAustria.filter(d => d.age_group == entry && d.gender == "Other" && d.vaccination == 3).reduce((a,b) => a + b.vaccinations_administered_cumulative, 0),
        immunisedPercent: 0
      }
    });

    datasetByAgeGroupAndGender = $populationStore.map(entry => {
      const ageGroup = entry["age_group"];
      const gender = entry["gender"];
      const population = entry["population"];
      const immunised = vaccinationDataAustria.filter(d => d.age_group == entry["age_group"] && d.gender == entry["gender"] && d.vaccination == 3).reduce((a,b) => a + b.vaccinations_administered_cumulative, 0);
      const immunisedPercent = immunised / population;

      return {
          ageGroup: ageGroup,
          gender: gender,
          population: population,
          immunised,
          immunisedPercent
      };
    }).concat(datasetNonBinary);


    const dataByAgeGroup = datasetByAgeGroupAndGender.reduce(
      (prevDataByAgeGroup, entry) => {
        if (!prevDataByAgeGroup[entry.ageGroup]) {
          prevDataByAgeGroup[entry.ageGroup] = {
            ageGroup: entry.ageGroup,
            population: 0,
            immunised: 0
          };
        }
        prevDataByAgeGroup[entry.ageGroup].population += entry.population;
        prevDataByAgeGroup[entry.ageGroup].immunised += entry.immunised;

        return prevDataByAgeGroup;
      },
      {}
    );

    datasetByAgeGroup = Object.values(dataByAgeGroup).map((entry) => {
      return {
        ...entry,
        immunisedPercent: 
          entry.population > 0 ? entry.immunised / entry.population : 0
      };
    });
  }
</script>

<div id="demographics">
  <div class="section-header space-x-2 pb-2 border-b-2 border-gray-100">
    <h2 class="text-xl font-bold">
      {$_('dashboard.vaccinationsByAge.title')}
    </h2>
  </div>
  <ChartByAgeGroup data={datasetByAgeGroup} />
  <div class="section-header space-x-2 pb-2 pt-8 border-b-2 border-gray-100">
    <h2 class="text-xl font-bold">
      {$_('dashboard.vaccinationsByAgeAndGender.title')}
    </h2>
  </div>
  <ChartByGenderAndAgeGroup data={datasetByAgeGroupAndGender} />
</div>
