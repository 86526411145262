<script>
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { scaleLinear, scaleOrdinal, scaleBand } from 'd3-scale';
  import { stack } from 'd3-shape';
  import { max } from 'd3-array';
  import dayjs from 'dayjs';
  import ChartTooltip from '../ChartTooltip.svelte';
  import { formatNumber } from '../../utils/formatter';

  const scrollId = `js-scroll-${Math.ceil(Math.random() * 1000)}`;

  export let data;
  export let keys;
  export let colors;

  let height = 0;
  let width = 0;
  let selectedBarIndex = null;
  $: showTooltip = selectedBarIndex !== null;

  onMount(() => {
    const wrapper = document.getElementById(scrollId);
    // this is not pretty :-)
    setTimeout(() => {
      wrapper.scrollLeft =
        wrapper.scrollLeftMax || wrapper.scrollWidth - wrapper.clientWidth;
    }, 200);
  });

  const margin = { top: 0, right: 50, bottom: 50, left: 20 };
  const bandwidth = 30;
  $: chartWidth = data.length * bandwidth;
  $: chartHeight = Math.max(height - margin.top - margin.bottom, 0);
  $: wrapperWidth = Math.max(width - margin.right - 10, 0);

  $: xScale = scaleBand()
    .rangeRound([0, chartWidth])
    .paddingInner(0.1)
    .paddingOuter(0)
    .domain(data?.map((d) => d.date));

  $: yScale = scaleLinear()
    .rangeRound([chartHeight, 0])
    .domain([0, max(data, (d) => d.firstDose + d.secondDose + d.thirdDose + d.boosterDose)]);

  $: path = `M${data
    .map(
      (d) =>
        `${xScale(d.date) + bandwidth},${yScale(
          d.dosesAdministredRollingAverage
        )}`
    )
    .join('L')}`;

  const colorScale = scaleOrdinal()
    .domain(keys.reverse())
    .range(colors.reverse());
  $: stackedDataByKey = stack().keys(keys)(data);

  const formatX = (d, i) => {
    return i % 7 !== 0 ? ' ' : dayjs(d.date).format('dd DD.MM.');
  };

  const handleMouseOver = function (_, i) {
    selectedBarIndex = i;
  };

  const handleTouchStart = function (_, i) {
    selectedBarIndex = i;
  };

  const handleMouseLeave = function () {
    selectedBarIndex = null;
  };

  const generateTooltip = function () {
    if (selectedBarIndex === null) return;
    const entry = data[selectedBarIndex];
    let text = $_('dashboard.vaccinationsPerDay.tooltip.vaccinations', {
      values: {
        totalVaccinations: $formatNumber(entry.dosesDaily),
        firstVaccinations: $formatNumber(entry.firstDose),
        secondVaccinations: $formatNumber(entry.secondDose),
        thirdVaccinations: $formatNumber(entry.thirdDose),
        boosterVaccinations: $formatNumber(entry.boosterDose),
        date: dayjs(entry.date).format('dddd, DD.MM')
      }
    });
    return text;
  };

  $: sevenDaysAverage = data[data.length - 1]['dosesAdministredRollingAverage'];
  $: sevenDaysSum = data[data.length - 1]['dosesCumulative'] - data[data.length - 8]['dosesCumulative'];
</script>

<div
  class="relative h-full"
  bind:clientHeight={height}
  bind:clientWidth={width}
>
  <div
    id={scrollId}
    class="scroll-wrapper"
    style={`width: ${wrapperWidth}px; height: ${height}px; `}
  >
    <svg width={chartWidth} height={'100%'}>
      {#each yScale.ticks(6) as tick}
        <g transform={`translate(0, ${yScale(tick)})`}>
          <line x1="0" x2={chartWidth} y1="1" y2="1" stroke="#E9E4DF" />
        </g>
      {/each}
      <g>
        {#each stackedDataByKey as stackedData}
          <g fill={colorScale(stackedData.key)}>
            {#each stackedData as d, i}
              <rect
                class="bar"
                x={xScale(data[i].date)}
                y={yScale(d[1])}
                height={yScale(d[0]) - yScale(d[1])}
                width={xScale.bandwidth()}
                opacity={!selectedBarIndex || selectedBarIndex === i ? 1 : 0.5}
                on:mouseover={() => handleMouseOver(d, i)}
                on:mouseleave={handleMouseLeave}
                on:touchstart={() => handleTouchStart(d, i)}
              />
            {/each}
          </g>
        {/each}
      </g>
      <g class="axis" transform={`translate(0, ${chartHeight})`}>
        <path
          d={[
            'M',
            xScale.range()[0],
            6,
            'v',
            -6,
            'H',
            xScale.range()[1],
            'v',
            6
          ].join(' ')}
          fill="none"
          stroke="currentColor"
        />
        {#each data as d, i}
          <g
            transform={`translate(${
              xScale(d.date) - xScale.bandwidth() / 2
            }, 0)`}
          >
            <text dy={18}>
              {formatX(d, i)}
            </text>
            <line y2={4} /></g
          >
        {/each}
      </g>
      <path class="path-line" d={path} />
    </svg>
  </div>
  <svg
    class="absolute top-0 pointer-events-none overflow-visible"
    {width}
    height="100%"
  >
    <g class="axis" transform={`translate(${width - margin.right}, 0)`}>
      {#each yScale.ticks(6) as tick}
        <g transform={`translate(0, ${yScale(tick) + 5})`}>
          <text>{$formatNumber(tick)}</text>
        </g>
      {/each}
    </g>
  </svg>
  {#if showTooltip}
    <ChartTooltip>
      {generateTooltip()}
    </ChartTooltip>
  {:else}
    <ChartTooltip>
      {@html $_('dashboard.vaccinationsPerDay.tooltip.default', {
        values: {
          sevenDaysSum: $formatNumber(sevenDaysSum),
          sevenDaysAverage: $formatNumber(sevenDaysAverage)
        }
      })}
    </ChartTooltip>
  {/if}
</div>

<style>
  .scroll-wrapper {
    overflow-x: scroll;
    height: 300px;
    width: 100%;
    scroll-behavior: smooth;
    padding-bottom: 10px;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .bar {
    transition: opacity 200ms ease-in-out;
  }

  .axis {
    color: #7c7e90;
  }

  .axis text {
    font-size: 14px;
    fill: currentColor;
  }

  .axis line {
    stroke: currentColor;
  }

  .path-line {
    fill: none;
    stroke: #083853;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-width: 2;
    stroke-dasharray: 5, 5;
  }
</style>
