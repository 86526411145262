<script>
  import { _, locale } from 'svelte-i18n';
  import { Link } from 'svelte-routing';
  import Popup from '../components/Popup.svelte';
  import DataTeaser from '../Components/DataTeaser/index.svelte';
  import VaccinationsOverview from '../components/VaccinationsOverview/index.svelte';
  import VaccinationsPerDay from '../components/VaccinationsPerDay/index.svelte';
  import VaccinationsRegions from '../components/VaccinationsRegions/index.svelte';
  import VaccinationsDemographics from '../components/VaccinationsDemographics/index.svelte';
  import VaccinationsCumulative from '../components/VaccinationsCumulative/index.svelte';
</script>

<Popup />

<section class="container mx-auto px-4 mt-16 space-y-16">
  <DataTeaser />

<a class="relative block bg-gray-800 max-w-4xl mx-auto transition hover:opacity-80  h-80" href="https://gemeinsamgeimpft.at" target="_blank">
<img src="/assets/images/campaign/background.jpg" alt="" class="object-cover absolute inset-0 w-full h-full rounded">
<div class="absolute inset-0" style="background: linear-gradient(100deg, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0) 60%);"></div>
<img src="/assets/images/campaign/slogan.svg" class="cursor-pointer absolute top-4 left-4 max-w-xl h-56 md:h-72" alt="Sich für alle Fragen Zeit nehmen. Sicher!">

<div class="campaign__label bg-campaign font-bold text-2xl px-3 py-1 inline-block text-white transition transform sm:scale-125 absolute bottom-0 -rotate-3 sm:translate-x-10">   
<img src="/assets/images/campaign/label.svg" class="cursor-pointer -mb-1 h-10" alt="#GemeinsamGeimpft">
</div>
</a>

  <VaccinationsOverview />
  <VaccinationsRegions />
  <VaccinationsPerDay />
  <VaccinationsCumulative />
  <VaccinationsDemographics />
</section>

<style global>
  .registration-content a {
    @apply underline text-green-dark;
  }

  .registration-content h3 {
    @apply font-bold mt-4;
  }

  .close {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }

  details > summary:first-of-type {
    list-style-type: none;
  }

  summary {
    cursor: pointer;
    width: calc(100% - 1em);
  }

  summary::-webkit-details-marker {
    display: none;
  }
  summary:after {
    content: '+';
    float: right;
    font-size: 1.5em;
  }
  details[open] summary:after {
    content: '-';
  }

  .no-arrow:after {
    content: '';
  }

  details[open] .no-arrow:after {
    content: '';
  }
</style>
