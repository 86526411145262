<script>
  import Loader from './Loader.svelte';
</script>

<div class="wrapper">
  <Loader />
  <span>Daten werden geladen...</span>
</div>

<style>
  .wrapper {
    width: 100vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  span {
    margin-top: 2em;
    color: #333333;
  }
</style>
