<script>
    import { _, locale } from 'svelte-i18n';
  </script>

<div class="mt-12 mb-8 container mx-auto px-4 max-w-4xl">
    {#if $locale === 'de'}
    <h2 class="text-2xl font-bold mb-4">Ihre Daten – Ihre Rechte</h2>
    <p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie der Meinung sind, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde.</p><p><strong>Datenschutzbeauftragte des Bundesministeriums für Soziales, Gesundheit, Pflege und Konsumentenschutz:</strong></p><p>Datenschutzbeauftragte des Sozialministeriums sind Frau&nbsp;Mag.a&nbsp;Eva-Maria Pfandlsteiner&nbsp;LL.M.&nbsp;und Herr&nbsp;Mag.&nbsp;Florian Reininger (Stubenring 1, 1010 Wien, vorname.nachname@sozialministerium.at).</p><p><strong>Matomo</strong></p><p>Die Website nutzt das Open-Source-Tool Matomo zur Web-Analyse. Matomo verwendet Cookies, die eine statistische Analyse der Nutzung der Website ermöglicht. Dazu werden die Nutzungsinformationen gesendet, wobei Ihre IP-Adresse sofort anonymisiert wird. Es werden somit keine personenbezogenen Daten für statistische Auswertungen gespeichert.&nbsp;<u><a href="https://matomo.org/privacy-policy/?lang=en" rel="noopener noreferrer nofollow">Matomo Privacy Policy</a></u></p>
    {:else}
    <h2 class="text-2xl font-bold mb-4">Ihre Daten – Ihre Rechte</h2>
    <p>Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie der Meinung sind, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde.</p><p><strong>Datenschutzbeauftragte des Bundesministeriums für Soziales, Gesundheit, Pflege und Konsumentenschutz:</strong></p><p>Datenschutzbeauftragte des Sozialministeriums sind Frau&nbsp;Mag.a&nbsp;Eva-Maria Pfandlsteiner&nbsp;LL.M.&nbsp;und Herr&nbsp;Mag.&nbsp;Florian Reininger (Stubenring 1, 1010 Wien, vorname.nachname@sozialministerium.at).</p><p><strong>Matomo</strong></p><p>Die Website nutzt das Open-Source-Tool Matomo zur Web-Analyse. Matomo verwendet Cookies, die eine statistische Analyse der Nutzung der Website ermöglicht. Dazu werden die Nutzungsinformationen gesendet, wobei Ihre IP-Adresse sofort anonymisiert wird. Es werden somit keine personenbezogenen Daten für statistische Auswertungen gespeichert.&nbsp;<u><a href="https://matomo.org/privacy-policy/?lang=en" rel="noopener noreferrer nofollow">Matomo Privacy Policy</a></u></p>
    {/if}

</div>