<script>
    import { _, locale } from 'svelte-i18n';
</script>
<div class="mt-12 mb-8 container mx-auto px-4 max-w-4xl">
{#if $locale === 'de'}
    <h2 class="text-2xl font-bold mb-4">Impressum</h2>
    <p>Angaben gemäß § 24 und § 25 des Mediengesetzes</p>
    <p class="mt-8"><b>Medieninhaber und Herausgeber</b><br>Bundesministerium für Soziales, Gesundheit, Pflege und Konsumentenschutz<br>Stubenring 1<br>1010 Wien<br>Telefon: +43 1 71100-0</p>
{:else}
<h2 class="text-2xl font-bold mb-4">Imprint</h2>
        <p>Information in accordance with § 24 and § 25 of the Media Act</p>
        <p class="mt-8">Media owner and publisher<br>Federal Ministry of Social Affairs, Health, Care and Consumer Protection<br>Stubenring 1<br>1010 Vienna<br>Tel.: +43 1 71100-0</p>

{/if}
</div>