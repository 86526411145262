<script>
  import { Link } from 'svelte-routing';
  import { _, locale } from 'svelte-i18n';
  import { reload } from '../i18n';
  import { currentPath } from '../data-store.js';

  $: re = null;
  $: if($currentPath) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  re = urlParams.get('re');
  }

  $: url = "";

  $: if(re !== null && $currentPath == "/") {
    url = "/" + re;
  } else if($currentPath) {
    url = $currentPath;
  } else {
    url = "/";
  }

  $: showNav = false;

  const languages = [{
    "key": "de",
    "label": "DE"
  }, {
    "key": "en",
    "label": "EN"
  }];

  $: selectedValue = "de";

</script>



<header class="bg-gray-100">
  <nav class="container mx-auto flex justify-between items-center text-lg md:text-xl">
      <Link to="/" class="cursor-pointer py-2 px-4 flex items-center space-x-2 {url === "/" ? "bg-white" : "bg-none"}">
        <img src="/assets/icons/flag.svg" alt="Österreich Flagge" class="w-6 h-6 inline">
        <h1 class="font-semibold inline">{$_('page.title')}</h1>
      </Link>

    <div class="md:ml-auto md:pr-8 text-base {showNav ? "absolute bottom-0 bg-gray-100 left-0 right-0 p-4 z-50" : "hidden"} md:block" on:mouseup={() => showNav = false}>
      <Link to="/opendata" >
        Open Data
      </Link>
    </div>

    <div class="flex gap-2 items-center text-base">
      <img src="/assets/icons/language.svg" alt="" />
      <select name="" id="" bind:value={selectedValue} on:change={() => reload(selectedValue)} class="bg-gray-100 appearance-none rounded-none border-none whitespace-normal break-words">
      {#each languages as language}
        <option value="{language.key}" selected="{language.key == $locale}">
        <span class="hidden sm:inline">{language.label}</span>
        </option>
      {/each}
      </select>
    </div>

    <button on:mousedown={() => showNav = !showNav} class="block md:hidden pr-4" aria-label="Navigation">
      <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6">
        <path d="M20.51 1.48999H1.51001" stroke="black" stroke-linecap="round"/>
        <path d="M20.5 8H1.5" stroke="black" stroke-linecap="round"/>
        <path d="M20.49 14.51H1.48999" stroke="black" stroke-linecap="round"/>
        </svg>          
    </button>
  </nav>
</header>

<style>

  @media screen and (min-width: 768px) {
    .header-wrapper {
    background: linear-gradient(90deg, #F6F4F0 0%, #F6F4F0 30%, #FFFFFF 40%, #FFFFFF 100%);
    }
  }
</style>
