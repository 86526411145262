<script>
  import { extent } from 'd3-array';
  import { scaleLinear } from 'd3-scale';
  import { _ } from 'svelte-i18n';
  import { formatNumber, formatPercentage } from '../../utils/formatter';
  import AgeGroupSelect from './AgeGroupSelect.svelte';

  import Bar from './Bar.svelte';

  const AGE_GROUPS = [
    '00-11',
    '12-14',
    '15-24',
    '25-34',
    '35-44',
    '45-54',
    '55-64',
    '65-74',
    '75-84',
    '85+'
  ];

  export let data;
  let width = 0;

  $: populationScale = scaleLinear()
    .domain(
      data
        ? extent(data.filter((d) => d.population > 0).map((d) => d.population))
        : [0, 0]
    )
    .range([30, 30]);

  const colorsByGender = {
    Male: ['#5fb564', '#5fb564', '#dfedda'],
    Female: ['#5fb564', '#5fb564', '#dfedda']
  };
  // first is text color, second bar, third background

  $: getLabels = (d) => {
    if (!d) return ['', ''];
    var translationKey;
    if(AGE_GROUPS.indexOf(d.ageGroup) < 2) {
    translationKey = d.gender === 'Male' ? 'countBoys' : 'countGirls';
    } else {
    translationKey = d.gender === 'Male' ? 'countMen' : 'countWomen';
    }
    return [
      $_(`dashboard.vaccinationsByAgeAndGender.${translationKey}`, {
        values: {
          countImmunised: $formatNumber(d.immunised),
          percentageImmunised: $formatPercentage(d.immunisedPercent),
          age: d.ageGroup
        }
      })
    ];
  };

  $: getPercentage = (d) => {
    if (!d) return [''];
    return [$formatPercentage(d.immunisedPercent)];
  };

  $: nonBinaryByAgeGroup = AGE_GROUPS.reduce((prevNonBinary, ageGroup) => {
    const dataForAgeGroup = data?.filter(
      (d) => d.ageGroup === ageGroup && d.gender === 'NonBinary'
    );
    if (
      dataForAgeGroup &&
      dataForAgeGroup.filter((d) => d.immunised > 0)?.length > 0
    ) {
      const immunisedSum = dataForAgeGroup.reduce(
        (sum, d) => d.immunised + sum,
        0
      );
      return {
        ...prevNonBinary,
        [ageGroup]: {
          immunised: immunisedSum
        }
      };
    } else {
      return { ...prevNonBinary, [ageGroup]: null };
    }
  }, {});

  $: activeAgeGroup = '15-24';

  $: getData = (gender, ageGroup) =>
    data?.find((d) => d.ageGroup === ageGroup && d.gender === gender);
</script>

<div bind:clientWidth={width} class="relative h-full pt-8">
  <span class="mr-2 font-semibold"
    >{$_('dashboard.vaccinationsDemographics.chooseAgeGroup')}:</span
  >
  <AgeGroupSelect
    on:changeAgeGroup={(event) => (activeAgeGroup = event?.detail)}
  />
  {#if activeAgeGroup}
    <div class="mb-5 mt-2">
      {#each ['Female', 'Male'] as gender}
        <Bar
          data={getData(gender, activeAgeGroup)}
          {width}
          height={populationScale(getData(gender, activeAgeGroup)?.population)}
          keys={['immunisedPercent']}
          gender={gender}
          colors={colorsByGender[gender]}
          labels={getLabels(getData(gender, activeAgeGroup))}
          percentages={getPercentage(getData(gender, activeAgeGroup))}
        />
      {/each}
      {#if nonBinaryByAgeGroup[activeAgeGroup]}
        <div
          class="pl-2 pt-2 mt-2 border-l-2 border-t-2 border-gray-500 text-gray-500"
        >
          <p class="max-w-2xl">
            {@html $_('dashboard.vaccinationsByAgeAndGender.countNonBinary', {
              values: {
                countImmunised: $formatNumber(nonBinaryByAgeGroup[activeAgeGroup].immunised),
                age: activeAgeGroup
              }
            }).replace(
              ', ',
              ', <span style="filter: brightness(0.8)" class="font-semibold">'
            )}
          </p>
        </div>
      {/if}
    </div>
  {/if}
</div>
