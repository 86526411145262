import { derived } from 'svelte/store';
import {
  timelineVaccinationsMunicipalitiesStore
} from '../../data-store';

export const municipalitiesStore = derived(
  timelineVaccinationsMunicipalitiesStore,
  ($timelineVaccinationsMunicipalitiesStore) => {
    const dataset = $timelineVaccinationsMunicipalitiesStore?.map((vMS) => ({
      id: vMS['municipality_id'],
      name: vMS['municipality_name'],
      population: vMS['municipality_population'],
      validCertificates: vMS['vaccination_3'],
      validCertificatesPercent: vMS['vaccination_3'] / vMS['municipality_population'],
      dose_1: vMS['vaccination_1'],
      dose_2: vMS['vaccination_2'],
      dose_3: vMS['vaccination_3'],
      dose_4: vMS['vaccination_4+'],
      dose_4_percentage: vMS['vaccination_4+'] / vMS['municipality_population']
    }));
    return dataset;
  }
);

export const districtsStore = derived(
  municipalitiesStore,
  ($municipalitiesStore) => {
    const getDistrictIdFromMunicipalityId = (municipalityId) => {
      const districtId = ('' + municipalityId).slice(0, 3);
      const isVienna = districtId[0] === '9';
      return isVienna ? '900' : districtId;
    };

    if(!$municipalitiesStore) return undefined;

    const municipalitiesByDistrictId = $municipalitiesStore.reduce(
      (prevMunicipalitiesByDistrictId, municipalityEntry) => {
        const districtId = getDistrictIdFromMunicipalityId(
          municipalityEntry.id
        );
        if (!prevMunicipalitiesByDistrictId[districtId])
          prevMunicipalitiesByDistrictId[districtId] = [];
        prevMunicipalitiesByDistrictId[districtId].push(municipalityEntry);
        return prevMunicipalitiesByDistrictId;
      },
      {}
    );

    return Object.entries(municipalitiesByDistrictId).map(
      ([districtId, municipalityEntries]) => {
        const districtEntry = municipalityEntries.reduce(
          (prevDistrictEntry, municipalityEntry) => {
            if (!prevDistrictEntry) {
              prevDistrictEntry = { ...municipalityEntry, id: districtId };
            } else {
              prevDistrictEntry.validCertificates +=
                municipalityEntry.validCertificates;
              prevDistrictEntry.dose_1 +=
                municipalityEntry.dose_1;
              prevDistrictEntry.dose_2 +=
                municipalityEntry.dose_2;
              prevDistrictEntry.dose_3 +=
                municipalityEntry.dose_3;
              prevDistrictEntry.dose_4 +=
                municipalityEntry.dose_4;
              prevDistrictEntry.population += municipalityEntry.population;
            }
            return prevDistrictEntry;
          },
          null
        );
        districtEntry.validCertificatesPercent =
          districtEntry.validCertificates / districtEntry.population;
        return districtEntry;
      }
    );
  }
);

export const statesStore = derived(
  municipalitiesStore,
  ($municipalitiesStore) => {
    const getStateIdFromMunicipalityId = (municipalityId) => {
      const districtId = ('' + municipalityId).slice(0, 3);
      const isVienna = districtId[0] === '9';
      return isVienna ? '9' : districtId[0];
    };

    if(!$municipalitiesStore) return undefined;

    const municipalitiesByStateId = $municipalitiesStore.reduce(
      (prevMunicipalitiesByStateId, municipalityEntry) => {
        const stateId = getStateIdFromMunicipalityId(
          municipalityEntry.id
        );
        if (!prevMunicipalitiesByStateId[stateId])
          prevMunicipalitiesByStateId[stateId] = [];
        prevMunicipalitiesByStateId[stateId].push(municipalityEntry);
        return prevMunicipalitiesByStateId;
      },
      {}
    );

    return Object.entries(municipalitiesByStateId).map(
      ([stateId, municipalityEntries]) => {
        const stateEntry = municipalityEntries.reduce(
          (prevStateEntry, municipalityEntry) => {
            if (!prevStateEntry) {
              prevStateEntry = { ...municipalityEntry, id: stateId };
            } else {
              prevStateEntry.validCertificates +=
                municipalityEntry.validCertificates;
                prevStateEntry.dose_1 +=
                municipalityEntry.dose_1;
                prevStateEntry.dose_2 +=
                municipalityEntry.dose_2;
                prevStateEntry.dose_3 +=
                municipalityEntry.dose_3;
                prevStateEntry.dose_4 +=
                municipalityEntry.dose_4;
                prevStateEntry.population += municipalityEntry.population;
            }
            return prevStateEntry;
          },
          null
        );
        stateEntry.validCertificatesPercent =
          stateEntry.validCertificates / stateEntry.population;
        return stateEntry;
      }
    );
  }
);
