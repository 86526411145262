<script>
  import { createEventDispatcher } from 'svelte';

  export let items;
  export let selectedItem;

  const dispatch = createEventDispatcher();

  function onChange(event) {
    dispatch('change', event.srcElement.value);
  }
</script>

<select
  class="flex-grow pl-4 pr-8 py-2 bg-white appearance-none rounded-none border-grey-500 border whitespace-normal break-words"
  bind:value={selectedItem}
  on:change={onChange}
>
  {#each items as item}
    <option value={item.key} selected={item.key === selectedItem}>
      {item.label}
    </option>
  {/each}
</select>

<style>
  /* Style the arrow inside the select element: */
  select {
    background: url(/assets/icons/up_down.svg) no-repeat 90% 50% white;
  }
</style>
