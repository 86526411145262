<script>
  import { _ } from 'svelte-i18n';
  import IntersectionObserver from 'svelte-intersection-observer';
  import Switch from '../Switch.svelte';
  import Chart from './Chart.svelte';
  import Table from './Table.svelte';
  import {
    municipalitiesStore,
    districtsStore,
    statesStore
  } from './regions-data-store';
  import Loader from '../Loader.svelte';

  $: regions = [
    {
      key: 'federalStates',
      label: $_('dashboard.regions.federalStates'),
      store: statesStore,
      hasLabels: true
    },
    {
      key: 'districts',
      label: $_('dashboard.regions.districts'),
      store: districtsStore,
      hasLabels: false
    },
    {
      key: 'municipalities',
      label: $_('dashboard.regions.municipalities'),
      store: municipalitiesStore,
      hasLabels: false
    }
  ];

  $: selectedRegion = 'federalStates';
  $: activeRegion = regions.find((region) => region.key === selectedRegion);
  $: activeStore = activeRegion?.store;
  $: dataset = $activeStore;
  let element;
  let loading = false;

  $: views = [
    {
      key: 'map',
      label: $_('dashboard.map'),
      icon: 'assets/icons/map.svg'
    },
    {
      key: 'table',
      label: $_('dashboard.table'),
      icon: 'assets/icons/table.svg'
    }
  ];
  $: activeView = 'map';

  $: layerForRegions = {};

  $: loadLayer = () => {
    if (!loading) {
      loading = true;
      fetch(`assets/js/regions/municipalities-low-res.json`)
        .then((response) => response.json())
        .then((data) => {
          if (!layerForRegions['municipalities'])
            layerForRegions['municipalities'] = data;
        });
      setTimeout(() => {
        regions.forEach((region) => {
          fetch(`assets/js/regions/${region.key.toLowerCase()}.json`)
            .then((response) => response.json())
            .then((data) => {
              layerForRegions[region.key] = data;
            });
        });
      }, 0);
    }
  };
</script>

<IntersectionObserver
  once={true}
  {element}
  on:observe={(e) => {
    return e?.detail.isIntersecting ? loadLayer() : null;
  }}
>
  <div id="federalStates" bind:this={element}>
    <div
      class="section-header md:space-x-2 pb-2 border-b-2 border-gray-100 md:flex space-y-2 md:space-y-0 justify-between md:items-center"
    >
      <h2 class="text-xl font-bold">
        {$_('dashboard.vaccinationsRegions.title')}
      </h2>
      <Switch
        {views}
        {activeView}
        on:itemClick={(event) => {
          activeView = event.detail;
        }}
      />
    </div>

    <div class="mt-4">
      <Switch
        views={regions}
        activeView={selectedRegion}
        on:itemClick={(event) => {
          selectedRegion = event.detail;
        }}
      />
    </div>
    {#if dataset && layerForRegions[activeRegion.key]}
      {#if activeView === 'map'}
        <div class="chart">
          <Chart
            data={dataset}
            layer={layerForRegions[activeRegion.key]}
            hasLabels={activeRegion.hasLabels}
          />
        </div>
      {:else}
        <Table
          data={dataset}
          layer={layerForRegions[activeRegion.key]}
          layerName={activeRegion.label}
        />
      {/if}
    {:else}
      <Loader />
    {/if}
  </div>
</IntersectionObserver>

<style>
</style>
