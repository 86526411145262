<script>
  import { _, locale } from 'svelte-i18n';
  import DataNotice from "../Components/DataNotice.svelte";
</script>

<div class="mt-12 container mx-auto mb-8 px-4">
<h2 class="text-2xl font-bold mb-4">Open Data</h2>
<div class="grid md:grid-cols-2 gap-8">
<div class="max-w-xl">
<h3 class="font-bold text-lg">Veröffentlichte Datensätze</h3>
<h4 class="mt-2 font-bold">COVID-19 Impfungen</h4>
<ul class="list-outside ml-5 list-disc max-w-xl underline">
<li><a href="#COVID19_vaccination_doses_timeline">Zeitreihe der verabreichten Impfungen der Corona-Schutzimpfung</a></li>
<li><a href="#COVID19_vaccination_doses_agegroups">Verabreichte Impfungen nach demografischen Gruppen</a></li>
<li><a href="#COVID19_vaccination_municipalities">Impfungen auf Gemeindeebene</a></li>
</ul>
<h4 class="mt-2 font-bold">COVID-19 Testungen</h4>
<ul class="list-outside ml-5 list-disc max-w-xl underline">
<li><a href="#testungen">Gemeldete Tests der Bundesländer</a></li>
<li><a href="#testungen-apothekenbetriebe">Gemeldete Tests von Apotheken und Betrieben</a></li>
</ul>
<p class="mt-6">Die Datensätze dürfen im Rahmen der Creative Commons-Lizenz mit Namensnennung frei verwendet werden. Geben Sie als Datenquelle bitte »BMSGPK, Österreichisches COVID-19 Open Data Informationsportal (https://www.data.gv.at/covid-19)« an. Wenn Sie eine Anwendung oder Visualisierung basierend auf den offenen Daten des Impfdashboards erstellt haben, <a href="http://data.gv.at/anwendung-einreichen" class="underline">melden Sie diese bitte in das Open Data Anwendungsverzeichnis ein</a>.</p>
</div>

<div class="bg-gray-100 p-4">
    <h4 class="font-bold" id="eimpfpass">Wichtige Hinweise zu den Daten aus dem e-Impfpass</h4>
      <ul class="list-outside ml-5 list-disc max-w-xl">
        <li class="mt-2">Geimpfte Personen werden im e-Impfpass ihrem Hauptwohnsitz zugeordnet, nicht dem Impfort. Auslieferungsdaten beziehen sich auf den Ort der Impfstelle.</li>
        <li class="mt-2">Impfungen werden im Datensatz dem Tag der Impfung zugeteilt. Da es im e-Impfpass zu Nachmeldungen kommt, verändern sich auch historische Daten.</li>
        <li class="mt-2">Verabreichte Impfungen, die laut Datensatz vor ihrer Zulassung oder ersten Lieferung verabreicht wurden, sind auf fehlerhafte Eintragungen durch die Impfstellen oder auf im Ausland verabreichte Impfungen an Personen mit österreichischem Wohnsitz, die nachträglich in den e-Impfpass eingetragen wurden, zurückzuführen.</li>
        <li class="mt-2">Im Rahmen laufender Qualitätssicherungen werden die e-Impfpass Daten regelmäßig bereinigt, weswegen es zu geringen Abweichungen im Vergleich zum Vortag kommen kann.</li>
      </ul>
    </div>
</div>

  <p class="mt-4"><a href="#changelog" class="font-bold">Zum Changelog springen &#8595;</a></p>
<h2 class="text-2xl mt-16">Impfungen</h2>
<div class="mt-8 flex text-gray-800">
  <h3 class="text-xl font-bold" id="COVID19_vaccination_doses_timeline">COVID19_vaccination_doses_timeline_v202210.csv</h3>
  <a href="/data/COVID19_vaccination_doses_timeline_v202210.csv">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="inline ml-2 w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
  </svg>
  </a>
  <a href="https://www.data.gv.at/katalog/dataset/85d040af-e09a-4401-8d67-8cee3e41fcaa"class="ml-2 underline">data.gv.at</a>
  </div>
  <p class="text-gray-800 mb-4 font-bold">Die Zeitreihe der verabreichten Impfungen der Corona-Schutzimpfung, aufgeteilt nach Hersteller, Impfung (1, 2, 3, 4+) und Bundesland. Ab 13.10.2022 in der Version 202210 verfügbar (geänderter Dateiname, URL und Variablennamen).</p>
  <DataNotice file={"COVID19_vaccination_doses_timeline_v202210.csv"} />
<table class="w-full table-fixed break-words border border-collapse">
<thead class="bg-gray-200 border font-bold">
        <td class="p-2 w-24 sm:w-36 md:w-64">Variable</td>
        <td class="p-2">Erklärung</td>
    </thead>
    <tr>
      <td class="p-2">date</td>
      <td class="p-2">Datum & Zeit der Datenaktualisierung im ISO8601-Format.</td>
    </tr>
    <tr>
      <td class="p-2">state_id</td>
      <td class="p-2">Die erste Stelle der Gemeindekennzahl, Österreich wird in Anlehnung an die Datensätze von AGES und Statistik Austria mit der ID 10 versehen. ID 0 / »NoState« fasst Impfungen zusammen, die keinem Bundesland zugeordnet werden können, weil die geimpfte Person keinen Hauptwohnsitz in Österreich hat.</td>
    </tr>
    <tr>
      <td class="p-2">state_name</td>
      <td class="p-2">Name des Bundeslandes</td>
    </tr>
    <tr>
      <td class="p-2">vaccine</td>
      <td class="p-2">Hersteller bzw. Impfstoff.</td>
    </tr>
    <tr>
      <td class="p-2">vaccination</td>
      <td class="p-2">Dosiszahl bzw. Impfserie. 4+ umfasst alle Impfungen ab der 4. Impfung</td>
    </tr>
    <tr>
      <td class="p-2">doses_administered_cumulative</td>
      <td class="p-2">Kumulative Anzahl der verabreichten Impfungen in der jeweiligen Kategorie</td>
    </tr>
      <tr class="text-gray-800">
          <td class="p-2" colspan="2"></td>
      </tr>
  </table>

  <div class="mt-8 flex text-gray-800">
    <h3 class="text-xl font-bold" id="COVID19_vaccination_doses_agegroups">COVID19_vaccination_doses_agegroups_v202210.csv</h3>
    <a href="/data/COVID19_vaccination_doses_agegroups_v202210.csv">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="inline ml-2 w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
    </svg>
    </a>
    <a href="https://www.data.gv.at/katalog/dataset/b82b5e53-e746-47b5-967a-e691b0d7ea6d" class="ml-2 underline">data.gv.at</a>
    </div>
    <p class="text-gray-800 mb-4 font-bold">Verabreichte Impfungen (1, 2, 3, 4+) pro Bundesland, aufgeteilt auf demografische Gruppen (Alter und Geschlecht). Dieses File wird tagesaktuell überschrieben, ein offizielles Archiv steht unter /data/archiv/COVID19_vaccination_agegroups_v202210_YYYYMMDD.csv zu Verfügung, z.B. <a href="/data/archiv/COVID19_vaccination_doses_agegroups_v202210_20221013.csv" class="underline">COVID19_vaccination_agegroups_v202210_20221013.csv</a>. Seit 13.10.2022 in einer aktualisierten Version mit geändertem Dateinamen und URL.</p>
    <DataNotice file={"COVID19_vaccination_doses_agegroups_v202210"} />
    <table class="w-full table-fixed break-words border border-collapse">
  <thead class="bg-gray-200 border font-bold">
          <td class="p-2 w-24 sm:w-36 md:w-64">Variable</td>
          <td class="p-2">Erklärung</td>
      </thead>
      <tr>
        <td class="p-2">date</td>
        <td class="p-2">Datum & Zeit der Datenaktualisierung im ISO8601-Format.</td>
      </tr>
      <tr>
        <td class="p-2">state_id</td>
        <td class="p-2">Die erste Stelle der Gemeindekennzahl, Österreich wird in Anlehnung an die Datensätze von AGES und Statistik Austria mit der ID 10 versehen. ID 0 / »NoState« fasst Impfungen zusammen, die keinem Bundesland zugeordnet werden können, weil die geimpfte Person keinen Hauptwohnsitz in Österreich hat.</td>
      </tr>
      <tr>
        <td class="p-2">state_name</td>
        <td class="p-2">Name des Bundeslandes</td>
      </tr>
      <tr>
        <td class="p-2">age_group</td>
        <td class="p-2"></td>
      </tr>
      <tr>
        <td class="p-2">gender</td>
        <td class="p-2"></td>
      </tr>
      <tr>
        <td class="p-2">vaccine</td>
        <td class="p-2">Hersteller bzw. Impfstoff.</td>
      </tr>
      <tr>
        <td class="p-2">vaccination</td>
        <td class="p-2">Dosisanzahl bzw. Impfserie. 4+ umfasst alle Auffrischungsimpfungen.</td>
      </tr>
      <tr>
        <td class="p-2">doses_administered_cumulative</td>
        <td class="p-2">Kumulative Anzahl der verabreichten Impfungen in der jeweiligen Kategorie</td>
      </tr>
        <tr class="text-gray-800">
            <td class="p-2" colspan="2"></td>
        </tr>
    </table>

    <div class="mt-8 flex text-gray-800">
      <h3 class="text-xl font-bold" id="COVID19_vaccination_municipalities">COVID19_vaccination_municipalities_v202210.csv</h3>
      <a href="/data/COVID19_vaccination_municipalities_v202210.csv">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="inline ml-2 w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
      </svg>
      </a>
      <a href="https://www.data.gv.at/katalog/dataset/ce666295-51d0-454c-99bd-f74a9439a37d"class="ml-2 underline">data.gv.at</a>
      </div>
      <p class="text-gray-800 mb-4 font-bold">Verabreichte Impfungen (1, 2, 3, 4+) pro Bundesland, aufgeteilt auf demografische Gruppen (Alter und Geschlecht). Dieses File wird tagesaktuell überschrieben, ein offizielles Archiv steht unter /data/archiv/COVID19_vaccination_agegroups_v202210_YYYYMMDD.csv zu Verfügung, z.B. <a href="/data/archiv/COVID19_vaccination_agegroups_v202210_20221013.csv" class="underline">COVID19_vaccination_agegroups_v202210_20221013.csv</a>. Seit 13.10.2022 in einer aktualisierten Version mit geändertem Dateinamen und URL.</p>
      <DataNotice file={"COVID19_vaccination_municipalities_v202210"} />
      <table class="w-full table-fixed break-words border border-collapse">
    <thead class="bg-gray-200 border font-bold">
            <td class="p-2 w-24 sm:w-36 md:w-64">Variable</td>
            <td class="p-2">Erklärung</td>
        </thead>
        <tr>
          <td class="p-2">date</td>
          <td class="p-2">Datum & Zeit der Datenaktualisierung im ISO8601-Format.</td>
        </tr>
        <tr>
          <td class="p-2">municipality_id</td>
          <td class="p-2">Code der Gemeinde, siehe <a href="http://www.statistik.at/web_de/klassifikationen/regionale_gliederungen/gemeinden/index.html" class="underline">Statistik Austria</a>.</td>
        </tr>
        <tr>
          <td class="p-2">municipality_name</td>
          <td class="p-2">Name der Gemeinde</td>
        </tr>
        <tr>
          <td class="p-2">municipality_population</td>
          <td class="p-2">Bevölkerung (Quelle: Statistik Austria)</td>
        </tr>
        <tr>
          <td class="p-2">vaccination_n</td>
          <td class="p-2">Verabreichte Impfungen nach Dosiszahl. dose_4+ beinhaltet alle Auffrischungsimpfungen.</td>
        </tr>
          <tr class="text-gray-800">
              <td class="p-2" colspan="2"></td>
          </tr>
      </table>

        

      <h2 class="text-2xl mt-16">Testungen</h2>
      <div class="mt-8 flex text-gray-800" id="testungen">
        <h3 class="text-xl font-bold">timeline-testungen-bundeslaender.csv</h3>
        <a href="/data/timeline-testungen-bundeslaender.csv">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="inline ml-2 w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
        </svg>
        </a>
<a href="https://www.data.gv.at/katalog/dataset/ca7c9b6f-ac7d-4918-8804-edd0942c5dd2" class="ml-2 underline">data.gv.at</a>
        </div>
        <p class="text-gray-800 mb-4 font-bold">Die gemeldeten COVID-19 Testungszahlen der Bundesländer an den nationalen Krisenstab. Die Zahl „Testung“ ist die Gesamtzahl aller durchgeführten PCR- und Antigen-Testungen. Die Daten dafür werden teilweise von den durchführenden Laboren gemeldet. Der Zeitverlauf wird von Montag bis Freitag (ausgenommen Feiertags) täglich aktualisiert.</p>
        <DataNotice file={"timeline-testungen-bundeslaender"} />
        <table class="w-full table-fixed break-words border border-collapse">
<thead class="bg-gray-200 border font-bold">
                <td class="p-2 w-24 sm:w-36 md:w-64">Variable</td>
                <td class="p-2">Erklärung</td>
            </thead>
            <tr>
              <td class="p-2">Datum</td>
              <td class="p-2">Datum & Zeit der Datenaktualisierung im ISO8601-Format.</td>
            </tr>
            <tr>
              <td class="p-2">BundeslandID</td>
              <td class="p-2">Die erste Stelle der Gemeindekennzahl, Österreich wird in Anlehnung an die Datensätze von AGES und Statistik Austria mit der ID 10 versehen.</td>
            </tr>
            <tr>
              <td class="p-2">Name</td>
              <td class="p-2">Name des Bundeslandes. Die letzte Zeile sind nationale Daten. Die Bundesländer sind nach dem amtlichen Gemeindeschlüssel sortiert.</td>
          </tr>
          <tr>
            <td class="p-2">Testungen</td>
            <td class="p-2">Gesamtzahl aller durchgeführten PCR- und Antigentestungen.</td>
          </tr>
          <tr>
            <td class="p-2">TestungenPCR</td>
            <td class="p-2">↑ davon PCR-Testungen</td>
          </tr>
          <tr>
            <td class="p-2">TestungenAntigen</td>
            <td class="p-2">↑ davon Antigen-Testungen</td>
          </tr>
        </table>

          <div class="mt-8 flex text-gray-800" id="testungen-apothekenbetriebe">
            <h3 class="text-xl font-bold">timeline-testungen-apotheken-betriebe.csv</h3>
            <a href="/data/timeline-testungen-apotheken-betriebe.csv">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="inline ml-2 w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
            </a>
            <a href="https://www.data.gv.at/katalog/dataset/76a266e7-752e-4979-a415-78a663c9cf53" class="ml-2 underline">data.gv.at</a>
            </div>
            <p class="text-gray-800 mb-4 font-bold">Die Zeitreihe der durchgeführten Testungen von Apotheken und Betrieben, welche an die Testplattform des Bundes angebunden sind. Die Betriebstestungen werden mit Stichtag 1. Mai nicht mehr aktualisiert. Der Zeitverlauf der Apothekentestungen wird von Montag bis Freitag (ausgenommen Feiertags) täglich aktualisiert.</p>
            <DataNotice file={"timeline-testungen-apotheken-betriebe"} />
            <table class="w-full table-fixed break-words border border-collapse">
    <thead class="bg-gray-200 border font-bold">
                    <td class="p-2 w-24 sm:w-36 md:w-64">Variable</td>
                    <td class="p-2">Erklärung</td>
                </thead>
                <tr>
                  <td class="p-2">Datum</td>
                  <td class="p-2">Datum & Stichzeitpunkt der Datenaktualisierung im ISO8601-Format.</td>
                </tr>
                <tr>
                  <td class="p-2">BundeslandID</td>
                  <td class="p-2">Die erste Stelle der Gemeindekennzahl, Österreich wird in Anlehnung an die Datensätze von AGES und Statistik Austria mit der ID 10 versehen.</td>
                </tr>
                <tr>
                  <td class="p-2">Name</td>
                  <td class="p-2">Name des Bundeslandes. Die letzte Zeile sind nationale Daten. Die Bundesländer sind nach dem amtlichen Gemeindeschlüssel sortiert.</td>
              </tr>
             <tr>
                <td class="p-2">TestungenApotheken</td>
                <td class="p-2">Die kumulative Anzahl der durchgeführten Tests in Apotheken.</td>
              </tr>
              <tr>
                <td class="p-2">TestungenApothekenPCR</td>
                <td class="p-2">davon PCR-Tests</td>
              </tr>
              <tr>
                <td class="p-2">TestungenApothekenAntigen</td>
                <td class="p-2">davon Antigen-Tests</td>
              </tr>
              <tr>
                <td class="p-2">TestungenBetriebe</td>
                <td class="p-2">Die kumulative Anzahl der durchgeführten Tests in Betrieben.</td>
              </tr>
            </table>
    <h3 class="text-xl mt-8" id="changelog">Changelog</h3>
      <ul class="list-outside list-disc max-w-xl ml-5">
          <li class="mt-2"><b>14.01.2021</b> 1. Release von national.csv und laender.csv auf <a href="https://www.data.gv.at/2021/01/14/offene-daten-zum-covid-19-impf-dashboard-veroeffentlicht/" class="underline">data.gv.at.</a></li>
          <li class="mt-2"><b>15.01.2021</b> laender.csv erhält die Variable BundeslandID und eine Zeile mit dem Letztstand. Außerdem wurde die Dokumentation um die Spalte »Name« ergänzt. Die Dokumentation von national.csv wurde um Hinweis zu Prognosedaten ergänzt.</li>
          <li class="mt-2"><b>16.01.2021</b> Durch eine Empfehlung der EMA wurden die von der Firma Biontec/Pfizer bestellten Vials am 15.01. von 5 auf 6 Impfungen pro Vial umgestellt. Diese Umstellung betrifft nicht die vor 16.1. ausgelieferten Impfungen.</li>
          <li class="mt-2"><b>27.01.2021</b> Daten aus dem e-Impfpass (EingetrageneImpfungen, Teilgeimpfte, Vollimmunisierte) wurden hinzugefügt. Ein neues Tabellenformat (timeline.csv) wurde eingeführt.</li>
          <li class="mt-2"><b>09.02.2021</b> Das Tabellenformat timeline.csv wurde in drei Tabellen, nach Datenquellen sortiert, aufgeteilt. Außerdem wurden neue Zeilen für BBG und e-Impfpass eingeführt, die Bestellungen des Bundes bzw. Impfungen, die keinem Bundesland zugeordnet werden, beschreiben. Es wurden neue demografische Daten für den e-Impfpass hinzugefügt (Alter, Geschlecht) sowie Informationen zu Impfungen nach Herstellern. Es wurden die von den Bundesländern gemeldeten Impfungen an den nationalen Krisenstab in einer weiteren Tabelle zugänglich gemacht.</li>
          <li class="mt-2"><b>05.03.2021</b> Die Tabelle timeline-eimpfpass.csv wurde rückwirkend um fehlerhafte Eintragungen bereinigt.</li>
          <li class="mt-2"><b>08.03.2021</b> Zwei neue .csv-Tabellen für Fallzahlen laut EMS und Bundesländermeldungen veröffentlicht.</li>
          <li class="mt-2"><b>13.03.2021</b> Die Größe der impfbaren Bevölkerung wurde korrigiert und stimmt nun mit den letzten Statistik Austria Zahlen (1.1.2020) überein.</li>
          <li class="mt-2"><b>01.04.2021</b> Die Bezeichnung der {'Gruppe<24'}, die Menschen zwischen 0 und 24 Jahren (inklusive) enthält, wurde auf {'Gruppe_<25'} korrigiert.</li>
          <li class="mt-2"><b>19.04.2021</b> Die Definition der Variablen Teilgeimpfte und Vollimmunisierte in der timeline-eimpfpass.csv wurde angepasst: Teilgeimpfte sind Personen mit mindestens einer Corona-Schutzimpfung, Vollimmunisierte sind Personen, die alle notwendigen Impfungen für einen vollständigen Impfschutz erhalten haben.</li>
          <li class="mt-2"><b>23.04.2021</b> Neue .csv-Tabelle mit gemeldeten Testungen an Schulen wurde veröffentlicht.</li>
          <li class="mt-2"><b>28.04.2021</b> Neue .csv-Tabelle mit gemeldeten Tests in Apotheken und Betrieben veröffentlicht.</li>
          <li class="mt-2"><b>11.05.2021</b> Die <a href="https://www.sozialministerium.at/Informationen-zum-Coronavirus/Neuartiges-Coronavirus-(2019-nCov).html" class="underline">Tabelle auf sozialministerium.at</a> wurde auf ein neues Format umgestellt und aktualisiert sich nun direkt aus den Open Data-Datensätzen.</li>
          <li class="mt-2"><b>19.05.2021</b> Der Open Data-Datensatz des BMSGPK zu Schultestungen in Österreich steht nicht mehr zu Verfügung. Die kumulativen Zahlen zu Testungen in Schulen finden Sie weiterhin auf <a href="https://www.sozialministerium.at/Informationen-zum-Coronavirus/Neuartiges-Coronavirus-(2019-nCov).html">https://www.sozialministerium.at/Informationen-zum-Coronavirus/Neuartiges-Coronavirus-(2019-nCov).html</a>. Ein erweiterter Datensatz zu Schultestungen ist durch das BMBWF in Vorbereitung, die Veröffentlichung erfolgt sobald wie möglich.</li>
          <li class="mt-2"><b>03.06.2021</b> Die Größe der impfbaren Bevölkerung wurde von 7.531.239 Personen (16+ Jahre) auf 7.872.174 Personen (12+ Jahre) vergrößert. Quelle: Statistik Austria 2020</li>
          <li class="mt-2"><b>11.06.2021</b> Der Datensatz timeline-bundeslaendermeldungen.csv wird von der timeline-eimpfpass.csv abgelöst, die bereits als primäre Datenquelle dient. Dort finden sich die Daten in einem höheren Detailgrad.</li>
          <li class="mt-2"><b>28.06.2021</b> Die Bevölkerungsstatistiken werden auf den Stand 1.1.2021 aktualisiert (laut Statistik Austria), auch rückwirkend bis zum Beginn der Datensätze. Die impfbare Bevölkerung sind somit 7.901.417 Menschen (12+ Jahre). Die Zuordnung zu Altersgruppen wurde ebenfalls aktualisiert, wodurch es zu Verschiebungen in den demografischen Daten kommt.</li>
          <li class="mt-2"><b>13.07.2021</b> Die {'Gruppe_<25'} wird in die {'Gruppe_<15'} und {'Gruppe_15-24'} aufgeteilt. Impfungen mit in Österreich nicht zugelassenen Impfstoffen werden unter ImpfstoffNichtZugeordnet ausgewiesen.</li>
          <li class="mt-2"><b>28.07.2021</b> Impfdaten auf Gemeindeebene werden in der impfungen-gemeinden.csv zu Verfügung gestellt. In Kürze folgt eine Darstellung auf dem Impfdashboard.</li>
          <li class="mt-2"><b>27.09.2021</b> Testungen in Apotheken werden in dem File timeline-testungen-apotheken-betriebe.csv ab jetzt auch getrennt nach PCR- und Antigen-Testungen angeführt. Die Variable »GemeldeteTestungen« heißt jetzt nur noch »Testungen«.</li>
          <li class="mt-2"><b>16.10.2021</b> COVID19_vaccination_doses_timeline.csv steht erstmals zu Verfügung.</li>
          <li class="mt-2"><b>24.10.2021</b> COVID19_vaccination_certificates.csv steht erstmals zu Verfügung. Gemeinsam mit COVID19_vaccination_doses_timeline.csv ersetzt dieses File ab 15. Dezember die timeline-eimpfpass.csv. Ein automatisiertes Archiv für tagesaktuelle Files steht ab heute unter /data/archiv zu Verfügung.</li>
          <li class="mt-2"><b>29.10.2021</b> COVID19_vaccination_municipalities.csv für Impfungen pro Hauptwohnsitzgemeinde (valide Impfzertifikate und verabreichte Impfungen) steht erstmals zu Verfügung. COVID19_vaccination_doses_agegroups.csv ergänzt als tagesaktuelles File mit feinerer Granularität in Altersgruppen und Geschlechtern die bestehenden Datensätze.</li>
          <li class="mt-2"><b>25.11.2021</b> Die impfbare Bevölkerung wurde von 7.901.417 Personen (12+ Jahre) auf 8.498.909 Personen (5+ Jahre) ausgeweitet. Quelle: Statistik Austria</li>
          <li class="mt-2"><b>01.03.2022</b> Zusätzliche Zeilen für Novavax wurden hinzugefügt und Novavax-Impfungen in die Berechnung der aktiven Impfzertifikate aufgenommen.</li>
          <li class="mt-2"><b>09.03.2022</b> COVID19_vaccination_municipalities.csv an Gemeindezusammenlegungen in Tirol (1.1.2022) angepasst. Bevölkerungsstatistiken auf Stand 1.1.2022 aktualisiert. Impfbare Bevölkerung auf 8.548.492 Personen aktualisiert (5+ Jahre). Quelle: Statistik Austria</li>
          <li class="mt-2"><b>24.06.2022</b> Datenhinweise für Open Data-Datensätze stehen unter der /data/ URL unter datensatzname_notices.json zu Verfügung, z.B. <a href="/data/timeline-faelle-ems_notices.json" class="underline">timeline-faelle-ems_notices.json</a>.</li>
          <li class="mt-2"><b>29.06.2022</b> Neue Bevölkerungszahlen der Statistik Austria (1.4.2022), neue Datei COVID19_vaccination_according_to_recommendation.csv, die die bisherige COVID19_vaccination_certificates.csv ersetzt. Anstelle der Berechnung anhand der tagesaktuell gültigen Impfzertifikaten in Österreich wird die Durchimpfungsrate zukünftig auf Basis der NIG-Empfehlungen berechnet. Neue Version 202206 für die Dateien COVID19_vaccination_doses_timeline_v202206.csv, COVID19_vaccination_doses_agegroups_v202206.csv und COVID19_vaccination_municipalities_v202206.csv. Die Datensätze enthalten nun die 4. Impfung und weitere Auffrischungsimpfungen. Die Zuordnung der Impfreihenfolge erfolgt zukünftig anhand des Eintragungsdatums anstatt der vermerkten Dosiskennung. Aus diesem Grund werden mit 29.06.2022 mehr gültige Einträge erkannt und die Anzahl der erfassten gültigen Impfungen erhöht sich geringfügig. (ca. 0,25%). Nicht zugelassene Impfstoffe werden nicht mehr berücksichtigt. Die alten Dateien werden bis 31.07.2022 weiterhin aktualisiert und ab dann archiviert.</li>
          <li class="mt-2"><b>13.09.2022</b> Die Datensätze timeline-faelle-bundeslaender.csv und timeline-faelle-ems.csv wurden eingestellt und werden <a href="https://covid19-dashboard.ages.at/" class="underline">durch die Daten der AGES</a> ersetzt. Die gemeldeten Tests der Bundesländer (Antigen und PCR) werden als eigene Datei timeline-testungen-bundeslaender.csv weiterhin veröffentlicht.</li>
          <li class="mt-2"><b>13.10.2022</b> Neue Datensätze für die COVID-19 Schutzimpfung (Version 202210) COVID19_vaccination_timeline_v202210.csv, COVID19_vaccination_agegroups_v202210.csv und COVID19_vaccination_municipalities_v202210.csv. Der Begriff »dose« wird in allen Datensätzen mit »vaccination« ersetzt. Die Kategorien »NonBinary« und »NotAssigned« werden zu der Variable »Other« zusammengelegt. Auffrischungsimpfungen werden unter »4+« zusammengefasst. Der Datensatz timeline-bbg.csv wurde eingestellt. </li>
        </ul>
    </div>