<script>
  import { _ } from 'svelte-i18n';
  import { formatPercentage, formatNumber } from '../../utils/formatter';
  import Table from '../Table.svelte';
  import Input from '../Input.svelte';

  export let data;
  export let layer;
  export let layerName;
  let limit = 10;
  let filterString = '';

  $: tableData = data
    ?.map((d) => {
      const feature = layer?.features.find((f) => f.properties.id === parseInt(d.id));
      return {
        ...d,
        name: feature?.properties?.name,
        code: feature?.properties?.code
      };
    })
    .filter((d) => {
      return (
        d.name.toLowerCase().includes(filterString.toLowerCase()) ||
        d.code?.toLowerCase().includes(filterString.toLowerCase())
      );
    });

  $: availableColumns = [
    {
      key: 'name',
      label: layerName
    },
    {
      key: 'validCertificates',
      label: $_('dashboard.validCertificates'),
      format: $formatNumber
    },
    {
      key: 'validCertificatesPercent',
      label: $_('dashboard.validCertificatesPercent'),
      format: $formatPercentage
    },
    {
      key: 'dose_1',
      label: $_('dashboard.dose_1'),
      format: $formatNumber
    },
    {
      key: 'dose_2',
      label: $_('dashboard.dose_2'),
      format: $formatNumber
    },
    {
      key: 'dose_3',
      label: $_('dashboard.dose_3'),
      format: $formatNumber
    },
    {
      key: 'dose_4',
      label: $_('dashboard.boosterDose'),
      format: $formatNumber
    }
  ];

  $: dataKeys = Object.keys(data?.[0]);
  $: columns = availableColumns.filter((column) =>
    dataKeys.includes(column.key)
  );
</script>

<div class="flex justify-center md:justify-end mt-6 mb-2">
  <Input
    bind:value={filterString}
    placeholder={$_('dashboard.vaccinationsRegions.search.placeholder')}
    icon="assets/icons/search.svg"
  />
</div>
<Table
  data={tableData}
  defaultSortBy={'validCertificatesPercent'}
  {columns}
  {limit}
/>

<span class="pt-4">{$_('dashboard.vaccinationsRegions.table.hint')}</span>

{#if limit < tableData?.length}
  <div class="mt-2 flex space-x-4">
    <span>
      {$_('dashboard.vaccinationsRegions.table.showing', {
        values: { showing: limit, of: `${tableData?.length} ${layerName}` }
      })}
    </span>
    <a
      href="javascript:void(0)"
      class="text-blue-light underline hover:text-blue cursor-pointer"
      role="button"
      tabindex="0"
      on:click={() => (limit += 10)}
    >
      {$_('dashboard.vaccinationsRegions.table.showMore', {
        values: { amount: Math.min(tableData?.length - limit, 10) }
      })}
    </a>
    
  

  </div>
{/if}
