<script>
  import { _ } from 'svelte-i18n';
  import { scaleLinear } from 'd3-scale';
  import { extent } from 'd3-array';
  import centroid from '@turf/centroid';
  import {
    LeafletMap,
    GeoJSON,
    Marker,
    Tooltip,
    ZoomControl
  } from 'svelte-leafletjs';
  import { LatLngBounds } from 'leaflet';
  import RegionSearch from './RegionSearch.svelte';
  import { formatNumber, formatPercentage } from '../../utils/formatter';
  import Switch from '../Switch.svelte';
  import ChartTooltip from '../ChartTooltip.svelte';
  import federalStates from './regions/states.json';

  export let data;
  export let layer;
  export let hasLabels = true;

  let regionSearch = null;
  let height = 0;
  let width = 0;
  let dragging = false;
  let timer = null;
  let leafletMap;
  let geojsonLayer;
  let selectedFeatureIdInternal = null;
  let previousSelectedFeatureIdInternal = null;
  let allowScroll = false;
  let moveToSelectedFeature = false;

  let blockHover = false;

  const mapOptions = {
    center: [47.608907, 14.138],
    zoom: 6.5,
    minZoom: 6.5,
    maxZoom: 12,
    zoomControl: false
  };

  $: visualizations = [
    {
      key: 'validCertificatesPercent',
      label: $_('dashboard.partlyVaccinated'),
      icon: null
    },
    {
      key: 'completelyVaccinatedPercent',
      label: $_('dashboard.completelyVaccinated'),
      icon: null
    }
  ];
  $: activeVisualization = 'validCertificatesPercent';

  const colorsByState = {
    default: ['#4D9553', '#366339'],
    validCertificatesPercent: ['#4D9553', '#366339'],
    completelyVaccinatedPercent: ['#4D9553', '#366339']
  };

  const fitBounds = (args) => {
    if (leafletMap) {
      var bounds = new LatLngBounds([
        [49.02116, 17.15786],
        [46.375377, 9.527906]
      ]);

      leafletMap.getMap().fitBounds(bounds);
    }
  };

  $: {
    if (leafletMap) {
      if (allowScroll) leafletMap.getMap().scrollWheelZoom.enable();
      else leafletMap.getMap().scrollWheelZoom.disable();
    }
  }

  $: {
    if (leafletMap && geojsonLayer) {
      geojsonLayer.getGeoJSON().eachLayer(function (featureInstanceLayer) {
        if (
          featureInstanceLayer.feature.properties.id ===
            selectedFeatureIdInternal &&
          moveToSelectedFeature
        ) {
          leafletMap.getMap().fitBounds(featureInstanceLayer.getBounds());
          moveToSelectedFeature = false;
        }
      });
    }
  }

  const setSelectedFeature = (
    selectedFeatureIdInternal,
    prevSelectedFeatureId,
    layer
  ) => {
    if (layer) {
      layer.getGeoJSON().eachLayer(function (featureInstanceLayer) {
        if (featureInstanceLayer.feature.properties.isOverlay) return;
        const id = featureInstanceLayer.feature.properties.id;
        if (id === prevSelectedFeatureId && id !== selectedFeatureIdInternal) {
          featureInstanceLayer.bringToBack();
          featureInstanceLayer.setStyle({
            color: '#ffffff',
            weight: 0.5,
            fillOpacity: selectedFeatureIdInternal ? 0.5 : 1
          });
        } else if (id === selectedFeatureIdInternal) {
          featureInstanceLayer.bringToFront();
          featureInstanceLayer.setStyle({
            color: '#f9b54f',
            weight: 1,
            fillOpacity: 1
          });
        } else if (
          prevSelectedFeatureId === null &&
          selectedFeatureIdInternal
        ) {
          featureInstanceLayer.setStyle({
            fillOpacity: 0.5
          });
        } else if (
          prevSelectedFeatureId !== null &&
          !selectedFeatureIdInternal
        ) {
          featureInstanceLayer.setStyle({
            fillOpacity: 1
          });
        }
      });
      previousSelectedFeatureIdInternal = selectedFeatureIdInternal;
    }
  };

  const setSelectedFeatureIdDebounced = (id) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      selectedFeatureIdInternal = id;
    }, 50);
  };

  $: fitBounds(leafletMap);
  $: setSelectedFeature(
    selectedFeatureIdInternal,
    previousSelectedFeatureIdInternal,
    geojsonLayer
  );

  $: centroidById = layer?.features.reduce((prevCentroids, feature) => {
    prevCentroids[feature.properties.id] =
      centroid(feature)?.geometry?.coordinates?.reverse();
    return prevCentroids;
  }, {});

  $: domain = extent(data.map((d) => d[activeVisualization]));
  $: colorScale = scaleLinear()
    .range(colorsByState[activeVisualization] || colorsByState['default'])
    .domain(domain);

  $: getColor = (feature) => {
    const dataForFeature = data.find((d) => d.id == feature.properties['id']);
    if (dataForFeature) return colorScale(dataForFeature[activeVisualization]);
  };

  $: getLabel = (feature) => {
    const dataForFeature = data.find((d) => d.id == feature.properties['id']);
    if (dataForFeature)
      return $formatPercentage(dataForFeature[activeVisualization], '.1%');
  };

  $: layerWithOverlayLayer = layer
    ? {
        ...layer,
        features: [
          ...layer.features,
          ...federalStates.features.map((f) => ({
            ...f,
            properties: { ...formatPercentage.properties, isOverlay: true }
          }))
        ]
      }
    : null;

  $: geoJsonOptions = {
    style: function (geoJsonFeature, layer) {
      if (geoJsonFeature.properties.isOverlay)
        return {
          stroke: true,
          color: '#ffffff',
          weight: 1.5,
          fill: false
        };
      else
        return {
          stroke: true,
          color: '#ffffff',
          weight: 0.5,
          fill: true,
          fillColor: getColor(geoJsonFeature),
          fillOpacity: 1
        };
    },
    onEachFeature: function (feature, layer) {
      layer.on('mousedown', function () {
        if (feature.properties.isOverlay) return;
        dragging = true;
      });
      layer.on('mouseup', function () {
        if (feature.properties.isOverlay) return;
        dragging = false;
      });
      layer.on('click', function () {
        setSelectedFeatureIdDebounced(null);
        fitBounds();
        blockHover = false;
        regionSearch?.reset();
      });
      layer.on('mouseover', function (e) {
        if (feature.properties.isOverlay) return;
        if (!dragging && !blockHover) {
          setSelectedFeatureIdDebounced(layer.feature.properties.id);
        }
      });
      layer.on('mouseout', function (e) {
        if (feature.properties.isOverlay) return;
        if (!dragging && !blockHover) {
          setSelectedFeatureIdDebounced(null);
        }
      });

      return {};
    }
  };

  $: generateTooltipText = () => {
    if (selectedFeatureIdInternal === null) return;
    const feature = layer?.features.find(
      (f) => f.properties.id === selectedFeatureIdInternal
    );
    const dataForFeature = data.find((d) => parseInt(d.id) === selectedFeatureIdInternal);
    const {
      validCertificates,
      validCertificatesPercent,
      dose_1,
      dose_2,
      dose_3,
      dose_4,
      dose_4_percentage
    } = dataForFeature || {};

    let text = $_('dashboard.vaccinationsRegions.tooltip.vaccinations', {
      values: {
        population: '',
        featureName: `<b>${feature?.properties.name}</b>`,
        validCertificates: $formatNumber(validCertificates),
        validCertificatesPercent: $formatPercentage(validCertificatesPercent),
        dose_1: $formatNumber(dose_1),
        dose_2: $formatNumber(dose_2),
        dose_3: $formatNumber(dose_3),
        booster: $formatNumber(dose_4),
        boosterRate: $formatPercentage(dose_4_percentage)
      }
    });
    return text;
  };

  $: handleKeydown = (event) => {
    if (['Meta', 'Control'].includes(event.key)) {
      allowScroll = true;
    }
  };

  $: handleKeyup = (event) => {
    if (['Meta', 'Control'].includes(event.key)) {
      allowScroll = false;
    }
  };

  $: tooltipText = generateTooltipText();
</script>

<svelte:window on:keydown={handleKeydown} on:keyup={handleKeyup} />

<div class="flex justify-center md:justify-end mt-6 mb-2">
  <RegionSearch
    bind:this={regionSearch}
    {layer}
    on:selectFeature={(e) => {
      if (e.detail.feature) {
        blockHover = true;
        selectedFeatureIdInternal = e.detail.feature?.properties?.id;
        moveToSelectedFeature = true;
      } else {
        fitBounds();
        selectedFeatureIdInternal = null;
        blockHover = false;
      }
    }}
    on:clearSelection={() => {
      blockHover = false;
    }}
  />
</div>
<div class="flex items-center flex-col">
  <div class="hidden">
    <Switch
      type="primary"
      views={visualizations}
      activeView={activeVisualization}
      on:itemClick={(event) => {
        activeVisualization = event.detail;
      }}
    />
  </div>

  <div
    class="relative top-12 md:top-6 w-full h-56 sm:h-64 lg:h-96 test"
    bind:clientHeight={height}
    bind:clientWidth={width}
  >
    {#if width && height}
      <LeafletMap bind:this={leafletMap} options={mapOptions}>
        <GeoJSON
          bind:this={geojsonLayer}
          geojson={layerWithOverlayLayer}
          options={geoJsonOptions}
        />
        {#if hasLabels}
          {#each layer.features as feature}
            {#if !feature.properties.isOverlay}
              <Marker
                latLng={centroidById[feature.properties.id]}
                opacity={0}
                options={{ interactive: false }}
              >
                <Tooltip
                  options={{
                    permanent: true,
                    offset: [-15, 25],
                    direction: 'center',
                    className: 'font-brand' 
                  }}
                >
                  {getLabel(feature)}
                </Tooltip>
              </Marker>
            {/if}
          {/each}
        {/if}
        <ZoomControl position="bottomright" />
      </LeafletMap>
    {/if}
    {#if selectedFeatureIdInternal}
      <ChartTooltip>
        <div contenteditable="false" bind:innerHTML={tooltipText} />
      </ChartTooltip>
    {/if}
  </div>
</div>

<style>
  @media screen and (max-width: 900px) {
    text {
      font-size: 0.8em;
    }
  }

  :global(.text-3) {
    transform: translate(-5px, 20px);
  }

  :global(.text-5) {
    transform: translate(0, 10px);
  }

</style>
