<script>
  import { extent } from 'd3-array';
  import { scaleLinear } from 'd3-scale';
  import { _ } from 'svelte-i18n';
  import { formatNumber, formatPercentage } from '../../utils/formatter';
  import AgeGroupSelect from './AgeGroupSelect.svelte';

  import Bar from './Bar.svelte';

  export let data;
  let width = 0;

  $: populationScale = scaleLinear()
    .domain(
      data
        ? extent(data.filter((d) => d.population > 0).map((d) => d.population))
        : [0, 0]
    )
    .range([30, 30]);

  const colors = ['#366339', '#4D9553', '#EEF3ED'];

  $: getLabels = (d) => {
    if (!d) return ['', ''];
    return [
      $_(`dashboard.vaccinationsByAge.count`, {
        values: {
          countImmunised: $formatNumber(d.immunised),
          percentageImmunised: $formatPercentage(d.immunisedPercent),
          age: d.ageGroup
        }
      })
    ];
  };

  $: getPercentage = (d) => {
    if (!d) return [''];
    return [$formatPercentage(d.immunisedPercent)];
  };

  $: activeAgeGroup = '15-24';

  $: getData = (ageGroup) => data?.find((d) => d.ageGroup === ageGroup);
</script>

<div bind:clientWidth={width} class="relative h-full pt-8">
  <span class="mr-2 font-semibold"
    >{$_('dashboard.vaccinationsDemographics.chooseAgeGroup')}:</span
  >
  <AgeGroupSelect
    on:changeAgeGroup={(event) => (activeAgeGroup = event?.detail)}
  />
  {#if activeAgeGroup}
    <div class="mb-5 mt-2">
      <Bar
        data={getData(activeAgeGroup)}
        {width}
        height={populationScale(getData(activeAgeGroup)?.population)}
        keys={['immunisedPercent']}
        {colors}
        labels={getLabels(getData(activeAgeGroup))}
        percentages={getPercentage(getData(activeAgeGroup))}
      />
    </div>
  {/if}
</div>
