<script>
  import './i18n';
  import { onMount, onDestroy } from 'svelte';
  import { isLoading, locale } from 'svelte-i18n';
  import { Router, Route, links } from 'svelte-routing';
  import { globalHistory } from 'svelte-routing/src/history';
  import dayjs from 'dayjs';
  import customParseFormat from 'dayjs/plugin/customParseFormat';
  import dayjsDe from 'dayjs/locale/de'; //required for locale-loading
  import dayjsEn from 'dayjs/locale/de'; //required for locale-loading
  import Dashboard from './pages/Dashboard.svelte';
  import Imprint from './pages/Imprint.svelte';
  import DataPrivacy from './pages/DataPrivacy.svelte';
  import Accessibility from './pages/Accessibility.svelte';
  import Footer from './components/Footer.svelte';
  import Header from './components/Header.svelte';
  import Opendata from './pages/OpenData.svelte';
  import LoadingScreen from './components/LoadingScreen.svelte';
  import { fade } from 'svelte/transition';
  import { timelineDosesStore, currentPath } from './data-store.js';
  import { defineCustomElements } from '@duetds/date-picker/custom-element';

  defineCustomElements(window);

  dayjs.extend(customParseFormat);
  $: dayjs.locale($locale);

  export let url;
  $: basepath =
    $locale?.length > 1 && $locale !== 'de' ? `${$locale}/` : undefined;

  $: delay = true;

  $: if ($timelineDosesStore) {
    delay = false;
  }

  var _paq = (window._paq = window._paq || []);
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function () {
    var u = '//an.ehealth.gv.at/';
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '7']);
    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.src = u + 'matomo.js';
    s.parentNode.insertBefore(g, s);
  })();

  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  $: re = urlParams.get('re');

  $: if (re === 'opendata') {
    url = 'opendata';
  } else if(re === 'anmeldung') {
    url = 'anmeldung'
  } else if(re === 'infektionslage') {
    url = 'infektionslage'
  }

  const isIE =
    !!navigator.userAgent.match(/Trident/g) ||
    !!navigator.userAgent.match(/MSIE/g);

  // add current url to global history

  let pathname = window.location.pathname;
  let unsub;

  onMount(() => {
    unsub = globalHistory.listen(({ location, action }) => {
      $currentPath = location.pathname;
    });
  });

  onDestroy(() => {
    unsub();
  });
</script>

{#if isIE}
  <div class="mx-auto bg-white p-4 z-50 text-brand">
    <img
      src="/assets/icons/attention.svg"
      alt="Warnung"
      class="w-10 h-10 mb-4"
    />
    <p>
      Dieser Inhalt kann in Ihrem Browser nicht dargestellt werden. Bitte
      verwenden Sie stattdessen eine moderne Version von Firefox, Google Chrome,
      Microsoft Edge, Safari oder rufen Sie die Datensätze alternativ über <a
        href="https://data.gv.at"
        class="underline">data.gv.at</a
      > ab.
    </p>
  </div>
{/if}

{#if $isLoading || delay}
  <LoadingScreen />
{:else}
  <div>
    <Router {url} {basepath}>
      <Header />
      <main transition:fade={{ delay: 100, duration: 1000 }} lang={$locale}>
        <Route path="opendata" component={Opendata} />
        <Route path="barrierefreiheit" component={Accessibility} />
        <Route path="impressum" component={Imprint} />
        <Route path="datenschutz" component={DataPrivacy} />
        <Route path="" component={Dashboard} />
      </main>
      <Footer />
    </Router>
  </div>
{/if}

<!-- End Matomo Code -->
<!-- Matomo Image Tracker-->
<img
  src="https://an.ehealth.gv.at/matomo.php?idsite=7&amp;rec=1"
  style="border:0"
  alt=""
/>

<!-- End Matomo -->
<style global>
  /* source-sans-pro-regular - latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/source-sans-pro-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/assets/fonts/source-sans-pro-v14-latin-regular.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('/assets/fonts/source-sans-pro-v14-latin-regular.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/source-sans-pro-v14-latin-regular.woff')
        format('woff'),
      /* Modern Browsers */
        url('/assets/fonts/source-sans-pro-v14-latin-regular.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/source-sans-pro-v14-latin-regular.svg#SourceSansPro')
        format('svg'); /* Legacy iOS */
  }
  /* source-sans-pro-600 - latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/source-sans-pro-v14-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/assets/fonts/source-sans-pro-v14-latin-600.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('/assets/fonts/source-sans-pro-v14-latin-600.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/source-sans-pro-v14-latin-600.woff') format('woff'),
      /* Modern Browsers */
        url('/assets/fonts/source-sans-pro-v14-latin-600.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/source-sans-pro-v14-latin-600.svg#SourceSansPro')
        format('svg'); /* Legacy iOS */
  }

  /* source-sans-pro-300 - latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/source-sans-pro-v14-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/assets/fonts/source-sans-pro-v14-latin-300.eot?#iefix')
        format('embedded-opentype'),
      /* IE6-IE8 */ url('/assets/fonts/source-sans-pro-v14-latin-300.woff2')
        format('woff2'),
      /* Super Modern Browsers */
        url('/assets/fonts/source-sans-pro-v14-latin-300.woff') format('woff'),
      /* Modern Browsers */
        url('/assets/fonts/source-sans-pro-v14-latin-300.ttf')
        format('truetype'),
      /* Safari, Android, iOS */
        url('/assets/fonts/source-sans-pro-v14-latin-300.svg#SourceSansPro')
        format('svg'); /* Legacy iOS */
  }

  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  text {
    font-family: 'Source Sans Pro';
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  html {
    scroll-behavior: smooth;
  }

  .leaflet-container {
    background-color: #fff !important;
  }

  .explainer > ul {
    list-style-type: disc;
  }

  details p, details ul {
    max-width: 40rem;
  }

  details ul {
    list-style-type: disc;
    list-style-position: inside;
  }
</style>
