<script>
  import { createEventDispatcher } from 'svelte';

  export let views;
  export let activeView;
  export let type = undefined; // use type="primary" for yellow switch

  const dispatch = createEventDispatcher();

  function handleClick(view) {
    dispatch('itemClick', view?.key || view, view);
  }
</script>

<!-- switch between views -->
<div class="switch " class:primary={type === 'primary'} tabindex=0>
  {#each views as view}
    <button
      class="element"
      class:active={view.key === activeView}
      on:click={() => handleClick(view)}
    >
      {#if view.icon}
        <img class="icon" alt={view.label} src={view.icon} />
      {/if}
      <span class="whitespace-nowrap">{view.label}</span>
    </button>
  {/each}
</div>

<style>
  .switch {
    @apply bg-gray-200 border-2 border-solid border-gray-200 rounded-full p-1 inline-flex items-center;
  }

  .primary {
    @apply bg-white border-blue text-blue;
  }

  .primary > .element {
    @apply bg-white;
  }

  .primary > .active {
    @apply bg-blue text-white;
  }

  .element {
    @apply bg-gray-200 px-4 py-1 rounded-full transition duration-100 flex items-center;
  }

  .active {
    @apply bg-white;
    font-weight: 700;
  }

  .icon {
    @apply w-6 h-6 mr-1;
  }
</style>
