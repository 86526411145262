import App from './App.svelte';
import 'leaflet/dist/leaflet.css';

const app = new App({
  hydrate: true,
  target: document.body,
  props: {
    name: 'Impfdashboard'
  }
});

export default app;
