// src/i18n.js
import { register, init, getLocaleFromPathname } from 'svelte-i18n';

register('en', function () {
  return window
    .fetch('/locales/en.json')
    .then((data) => data.json())
    .catch((error) => console.log('i18n fetch error', error));
});
register('de', function () {
  return window
    .fetch('/locales/de.json')
    .then((data) => data.json())
    .catch((error) => console.log('i18n fetch error', error));
});

init({
  fallbackLocale: 'de',
  initialLocale: getLocaleFromPathname(/^\/(.{2})($|\/)/)
});

function reload(locale) {
  init({
    fallbackLocale: 'de',
    initialLocale: locale
  })
}

export { init, reload };
// starts loading 'en-US' and 'en'
