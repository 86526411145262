<script>
  import { Link } from 'svelte-routing';
  import { _ } from 'svelte-i18n';
</script>

<footer class="bg-gray-100 px-4 py-8 flex items-center">
    <p class="mx-auto space-x-2 text-center leading-loose">
      <Link to="/opendata">Open Data</Link>
      <span>/</span>
      <Link to="/impressum">{$_("dashboard.imprint")}</Link>
      <span>/</span>
      <Link to="/datenschutz">{$_("dashboard.privacy")}</Link>
      <span>/</span>
      <Link to="/barrierefreiheit">{$_("dashboard.accessibility")}</Link>
    </p>
</footer>

<style>
</style>
