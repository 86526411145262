<script>
  export let keys;
  export let colors;
  export let labels;
</script>

<div class="legend space-x-6">
  {#each keys as _, i}
  <div class="flex items-center space-x-2">
    <div class="indicator" style={`--color: ${colors[i]}`} />
    <span>{labels[i]}</span>
  </div>
  {/each}
</div>

<style>
  .legend {
    @apply flex mt-2 items-center text-sm;
  }

  .indicator {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--color);
  }
</style>
