<script>
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { scaleLinear, scaleTime } from 'd3-scale';
  import { max, extent, bisector } from 'd3-array';
  import { pointer } from 'd3-selection';
  import { area, curveBasis, line, stack } from 'd3-shape';
  import dayjs from 'dayjs';
  import ChartTooltip from '../ChartTooltip.svelte';
  import { formatNumber } from '../../utils/formatter';

  export let data;

  export let keys;
  export let colors;

  let height = 0;
  let width = 0;
  let selectedIndex = null;
  $: showTooltip = selectedIndex !== null;

  const margin = { top: 5, right: 50, bottom: 50, left: 20 };
  const bandwidth = 30;
  $: chartWidth = width - margin.left - margin.right;
  $: chartHeight = Math.max(height - margin.top - margin.bottom, 0);

  $: xScale = scaleTime()
    .domain(
      extent(data, function (d) {
        return new Date(d.date);
      })
    )
    .range([0, chartWidth]);

  $: yScale = scaleLinear()
    .rangeRound([chartHeight, 0])
    .domain([0, max(data, (d) => d.dosesCumulative + 1000000)]);

  $: generateArea = (key) => {
    return area()
      .x(function (d) {
        return xScale(new Date(d.data.date));
      })
      .y0((d) => yScale(d[0]))
      .y1((d) => yScale(d[1]))
      .curve(curveBasis);
  };

  $: stackData = stack().keys(keys)(data);
  $: areas = keys?.map((key, i) => generateArea(key)(stackData[i]));
  const formatX = (d, i) => {
    return dayjs(d.date).format('dd DD.MM.');
  };

  $: generateTooltip = function () {
    if (selectedIndex === null) return '';
    const entry = data[selectedIndex];
    const dosesAdministered = entry['dosesCumulative'];
    const formattedSelectedDay = dayjs(entry.date).format('dddd, DD.MM.,');

    let text = $_('dashboard.vaccinationsCumulative.tooltip.vaccinations', {
      values: {
        date: formattedSelectedDay,
        totalVaccinations: $formatNumber(dosesAdministered)
      }
    });

    return text;
  };

  const handleMouseMove = function (event) {
    const [currentX] = pointer(event);
    const date = xScale.invert(currentX);
    const index = bisector((d) => new Date(d.date)).left(data, date, 1);
    const p0 = xScale(new Date(data[index - 1].date));
    const p1 = xScale(new Date(data[index].date));
    const useIndex =
      Math.abs(p0 - currentX) > Math.abs(p1 - currentX) ? index : index - 1;

    selectedIndex = useIndex >= 0 ? useIndex : null;
  };

  const handleMouseLeave = function () {
    selectedIndex = null;
  };
</script>

<div
  class="relative h-full"
  bind:clientHeight={height}
  bind:clientWidth={width}
>
  <svg {width} height={'100%'}>
    {#each yScale.ticks(6) as tick}
      <g transform={`translate(0, ${yScale(tick)})`}>
        <line
          x1="0"
          x2={width - margin.right - margin.left}
          y1="1"
          y2="1"
          stroke="#E9E4DF"
        />
      </g>
    {/each}
    <g style="transform: translate(3px,0)">
      {#each areas as area, i}
        <path
          d={area}
          fill={colors[i]}
          class="chart-area"
          on:mousemove={handleMouseMove}
          on:mouseleave={handleMouseLeave}
        />
      {/each}
    </g>
    <g class="bg-axis" transform={`translate(0, ${chartHeight})`}>
      <path
        d={[
          'M',
          xScale.range()[0],
          6,
          'v',
          -6,
          'H',
          xScale.range()[1],
          'v',
          6
        ].join(' ')}
        fill="none"
        stroke="#fff"
        stroke-width="4"
      />
    </g>
    <g class="axis" transform={`translate(0, ${chartHeight})`}>
      <path
        d={[
          'M',
          xScale.range()[0],
          6,
          'v',
          -6,
          'H',
          xScale.range()[1],
          'v',
          6
        ].join(' ')}
        fill="none"
        stroke="currentColor"
      />
      {#each data as d, i}
        {#if i % 7 === 0}
          <g transform={`translate(${xScale(new Date(d.date))}, 0)`}>
            <text dy={18} text-anchor={i === 0 ? 'left' : 'middle'}>
              {formatX(d, i)}
            </text>
            <line y2={4} /></g
          >
        {/if}
      {/each}
    </g>
    <g>
      <rect
        x={selectedIndex !== null
          ? xScale(new Date(data[selectedIndex].date)) - 1.5
          : -10}
        y={0}
        height={chartHeight}
        width={3}
        fill="white"
        opacity={0.5}
        pointer-events="none"
      />
    </g>
  </svg>
  <svg
    class="absolute top-0 pointer-events-none overflow-visible"
    {width}
    height="100%"
  >
    <g class="axis" transform={`translate(${width - margin.right}, 0)`}>
      {#each yScale.ticks(6) as tick, i}
        <g transform={`translate(0, ${yScale(tick) + 5})`}>
          <text>{parseInt(tick) / 1000000} Mio</text>
        </g>
      {/each}
    </g>
  </svg>
  {#if showTooltip}
    <ChartTooltip>
      {generateTooltip()}
    </ChartTooltip>
  {/if}
</div>

<style>
  .scroll-wrapper {
    overflow-x: scroll;
    height: 220px;
    width: 100%;
    scroll-behavior: smooth;
    padding-bottom: 10px;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  .axis {
    color: #7c7e90;
  }

  svg {
    padding-top: 2px;
  }

  .axis text {
    font-size: 14px;
    fill: currentColor;
  }

  .axis line {
    stroke: currentColor;
  }

  .chart-area {
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .chart-line {
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
  }
</style>
