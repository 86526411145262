<script>
  import Select from '../Select.svelte';
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  const AGE_GROUPS = [
    '00-11',
    '12-14',
    '15-24',
    '25-34',
    '35-44',
    '45-54',
    '55-64',
    '65-74',
    '75-84',
    '85+'
  ];

  function onChange(event) {
    dispatch('changeAgeGroup', event?.detail);
  }

  $: items = AGE_GROUPS.reverse().map((ageGroup) => ({
    key: ageGroup,
    label: `${ageGroup} ${$_('dashboard.years')}`
  }));

  const selectedAgeGroup = '15-24';
</script>

<Select
  {items}
  selectedItem={selectedAgeGroup}
  on:change={(key) => onChange(key)}
/>
