<script>
    import { timelineDosesStore } from '../../data-store';
    import dayjs from 'dayjs';
    import { _ } from 'svelte-i18n';
    import { formatNumber, formatPercentage } from '../../utils/formatter';

    $: dateLastDataUpdate = dayjs();
    $: dosesSum = 0;
    $: dosesBooster = 0;
    $: dosesLastWeek = 0;
    $: dosesPreviousWeek = 0;
    $: dosesWeeksDifference = 0;
    $: dosesAustria = [];
    let i;
    let j;

    $: if ($timelineDosesStore) {
        const data = $timelineDosesStore;
        dateLastDataUpdate = data[data.length - 1]["date"];
        
        dosesAustria =
        data?.filter(
        (entry) => entry['state_id'] === 10
        );
    }

    $: if(dosesAustria.length > 0) {
        const latestDate = dosesAustria[dosesAustria.length - 1].date;
        dosesSum = 0;
        dosesBooster = 0;
        dosesLastWeek = 0;
        dosesPreviousWeek = 0;
        dosesWeeksDifference = 0;
        for(i = dosesAustria.findIndex(d => d.date.toString() == latestDate.toString()); i < dosesAustria.length; i++) {
            // cycle through last day in dataset
            dosesSum += dosesAustria[i]["vaccinations_administered_cumulative"];
            if(dosesAustria[i]["vaccination"] === "4+") {
                dosesBooster += dosesAustria[i]["vaccinations_administered_cumulative"]
            }
        }
        
        dosesLastWeek = dosesSum - dosesAustria.filter(d => dayjs(d.date).isSame(dayjs(latestDate).subtract(7, "day"))).reduce((a,b) => a + b.doses_administered_cumulative, 0);
        dosesPreviousWeek = dosesLastWeek - dosesAustria.filter(d => dayjs(d.date).isSame(dayjs(latestDate).subtract(14, "day"))).reduce((a,b) => a + b.doses_administered_cumulative, 0);
        dosesWeeksDifference = dosesLastWeek - dosesPreviousWeek;
      }
</script>

<div class="text-2xl leading-tight px-4 mt-16">
      <p class="max-w-3xl">{@html $_('dashboard.overview.headline', {
        values: {
          dateLastDataUpdate: dayjs(dateLastDataUpdate).format("D. MMMM"),
          dosesSum: $formatNumber(dosesSum),
          dosesBooster: $formatNumber(dosesBooster),
          boosterHelp: "<span class='inline-block w-4 h-4 pl-1.5 bg-green-dark text-xs font-bold text-white rounded-full transform -translate-y-3 -translate-x-1'>?</span>"
        }
      })}</p>
      <p class="text-base mt-2">{$_('dashboard.overview.explainer')}</p>
</div>


<style>

</style>