<script>
  import { scaleLinear } from 'd3-scale';

  export let data;
  export let gender;
  export let keys;
  export let colors;
  export let width;
  export let height = 12;
  export let labels;
  export let percentages;

  const AVG_CHARACTER_WIDTH = 10;

  $: xScale = scaleLinear().domain([0, 1]).range([0, width]);

  $: hasLabelsInside = data
    ? keys
        .map((key, i) => {
          return 60 < (xScale(data[key]) || 0);
        })
        .every((v) => v === true)
    : false;
</script>

{#if data && keys}
  <div class="bar" style={`height: ${height}px; background: ${colors[2]};`}>
    <div class="flex" style="">
      {#each keys as key, i}
        <div
          class="bar--item"
          style={`width: ${xScale(data[key] || 0)}px; background-color: ${
            colors[1]
          }`}
        >
          {#if hasLabelsInside}
            <div class="relative text-white h-full font-bold">
              <div
                class="absolute right-2 bar-label-inside-{key} flex items-center space-x-1"
                style="top: 50%; transform: translateY(-50%);"
                >
                {#if gender == "Female"}
                <img src="/assets/icons/female.svg" alt="Weiblich" class="w-5">
                {:else if gender == "Male"}
                <img src="/assets/icons/male.svg" alt="Männlich" class="w-5">
                {/if}
                <span>{percentages[i]}</span></div
              >
            </div>
          {/if}
        </div>
      {/each}
      <!--
      {#if !hasLabelsInside}
        <div class="flex flex-col justify-center ml-2 leading-tight text-sm md:text-base">
          {#each labels as label, i}
            <span style={`color: ${colors[i]}`} class="bar-label-outside">{label}</span>
          {/each}
        </div>
      {/if}
      -->
    </div>
  </div>
  <div
    class="pl-2 pt-2"
    style="border-left: 2px solid {colors[1]}; color: {colors[0]}"
  >
    <p class="max-w-4xl">
      {@html labels[0].replace(
        ', ',
        ', <span style="filter: brightness(0.8)" class="font-semibold">'
      )}
    </p>
  </div>
{/if}

<style>
  .bar {
    /* border: 2px solid #D0A1AB; */
    width: 100%;
    display: flex;
    margin-top: 0.8em;
  }

  .bar--item {
    height: 100%;
    opacity: 90%;
  }

  .bar-label-outside {
    filter: brightness(80%);
  }
</style>
