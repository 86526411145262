<script>
    import dayjs from "dayjs";
    import { _ } from "svelte-i18n";
    export let file;

    async function getNotices(filename) {
    let response = await fetch('/data/' + filename + '_notices.json');
    let data = await response.json();
    if (response.ok) {
    return data;
	  } else {
	  throw new Error(data);
	}
  }

  $: promise = getNotices(file);

  $: showAll = false;
</script>

{#await promise}
Loading...
{:then notices}
<div class="bg-yellow-lightest p-2 mb-4 relative">
    <h4 class="font-bold">{$_('dataNotice.heading')}</h4>
    <ul>
        {#each [...notices].sort((a,b) => a.date < b.date).splice(0,showAll ? 100 : 1) as notice}
        {#if showAll || dayjs(notice.date).add(7, 'day') > dayjs()}
        <li>
            <b>{dayjs(notice.date).format("DD.MM.YYYY")}</b>: {notice.notice}
        </li>
        {:else}
        {$_('dataNotice.noCurrentNotices')}
        {/if}
        {/each}
    </ul>   
    <a href="/data/{file}_notices.json" class="absolute top-2 right-2 opacity-50 transition hover:opacity-100 flex items-center gap-2">
        <span>{$_('dataNotice.download')}</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-description" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
            <path d="M9 17h6"></path>
            <path d="M9 13h6"></path>
         </svg>
    </a>

    <button on:mousedown={() => showAll = !showAll} class="underline mt-1">
        {#if showAll}
        {$_('dataNotice.showLess')} &uarr;
        {:else}
        {$_('dataNotice.showMore')} &darr;
        {/if}
    </button>
</div>
{:catch error}
{/await}
