<script>
import Papa from "papaparse";

export let file = "disclaimer-impfzahlen";
export let type = "dashboard";

$: disclaimer = undefined;

Papa.parse('../data/' + file + '.csv?v=' + Math.random(), {
  download: true,
  dynamicTyping: true,
  complete: function (results) {
    if (results) {
      disclaimer = results.data;
      if (results.data == '' || results.data[0] == '<!DOCTYPE html>') {
        disclaimer = undefined;
      }
    }
  }
});
</script>

{#if disclaimer !== undefined}
    <div class="flex {type == "embed" ? "bg-coldGray-100 text-black" : "bg-gray-100 text-brand"} p-4 items-center disclaimer">
      <img
        src="/assets/icons/attention.svg"
        alt="Warning"
        class="mr-4"
        style="min-width: 2em;"
      />
      <div>
        {#each disclaimer as paragraph}
          <p>{@html paragraph}</p>
        {/each}
      </div>
    </div>
{/if}

<style>
:global(.disclaimer a) {
    text-decoration: underline;
}
</style>